import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {XMarkIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";

export default function NumberFromToSelector(
    props:{
        name:string,
        from:number|undefined,
        to:number|undefined,
        updated:(from:number|undefined, to:number|undefined) => void,
        search:() => void
    }
) {
    const [valueFrom, setValueFrom] = useState<number|"">(props.from||"");
    const [valueTo, setValueTo] = useState<number|"">(props.to||"");

    useEffect(() => {
        valueUpdated();
    }, [valueFrom, valueTo])

    function valueUpdated() {
        props.updated(
            valueFrom === "" ? undefined : valueFrom,
            valueTo === "" ? undefined : valueTo
        );
    }

    function updateFromFilter(value:string) {
        let valueFromNumber = Number(value.replace(/[^0-9.,]+/g, "")) || undefined;
        setValueFrom(valueFromNumber||"");
    }

    function updateToFilter(value:string) {
        let valueToNumber = Number(value.replace(/[^0-9.,]+/g, "")) || undefined;
        setValueTo(valueToNumber||"");
    }

    function resetValues() {
        setValueFrom("");
        setValueTo("");
        props.search();
    }

    function handleKeyDown(key: string) {
        if (key.toLowerCase() === "enter")
            props.search();
    }

    return (
        <div>
            <div className="flex">
                <div className="grow">
                    <div className="grid grid-cols-2">
                        <input
                            type="text"
                            name={`${props.name}From`}
                            id={`${props.name}From`}
                            className="block rounded-l-md py-1 px-3 border-0 text-gray-900 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-200 sm:text-sm sm:leading-6"
                            placeholder="0.00"
                            value={valueFrom}
                            onChange={(e) => { updateFromFilter(e.target.value) }}
                            onKeyDown={(e) => {handleKeyDown(e.key)}}
                        />
                        <input
                            type="text"
                            name={`${props.name}To`}
                            id={`${props.name}To`}
                            className="block -ml-px py-1 px-3 border-0 text-gray-900 ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-200 sm:text-sm sm:leading-6"
                            placeholder="9999.00"
                            value={valueTo}
                            onChange={(e) => { updateToFilter(e.target.value)}}
                            onKeyDown={(e) => {handleKeyDown(e.key)}}
                        />
                    </div>
                </div>
                <button
                    type="button"
                    className="shrink  gap-x-1.5 bg-frog-200 -ml-px p-2 text-sm font-semibold text-white shadow-sm hover:bg-frog-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-frog-200"
                    onClick={props.search}
                >
                    <MagnifyingGlassIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                </button>
                <button
                    type="button"
                    className="inline-flex gap-x-1.5 rounded-r-md bg-gray-300 p-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300"
                    onClick={resetValues}
                >
                    <XMarkIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                </button>
            </div>
        </div>
    );
}