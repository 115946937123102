import React, {Fragment, JSXElementConstructor, ReactElement, useRef, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {useAuth0} from "@auth0/auth0-react";
import {addUserRequest} from "../services/userRequests";
import {AUTH0_AUDIENCE} from "../config";
import UserRequestCreate from "../classes/UserRequestCreate";
import {Spinner} from "./Spinner";

export default function ContactUsModal(props:{onClose: (result:boolean) => void}) {
    const [open, setOpen] = useState(true);
    const [comment, setComment] = useState("");
    const [isSending, setIsSending] = useState<boolean>(false);
    const {user, getAccessTokenSilently} = useAuth0();


    function createRequest() {
        setIsSending(true);
        getAccessTokenSilently({
            authorizationParams: {
                audience: AUTH0_AUDIENCE
            }
        }).then((token) => {
            let request: UserRequestCreate = {
                firstName: user?.given_name,
                lastName: user?.family_name,
                email: user?.email,
                phone: user?.phone_number,
                websiteId: undefined,
                comment: comment
            };
            addUserRequest(token, request).then((data) => {
                setOpen(false);
                setIsSending(false);
                props.onClose(true);
            });
        });
    }

    function cancelRequest() {
        setOpen(false);
        props.onClose(false);
    }

    const cancelButtonRef = useRef(null)

    function changComment(value: string) {
        setComment(value);
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-lg sm:p-6">
                                <div>
                                    <div className="my-5 text-xl text-center">
                                        <h1>Describe your request:</h1>
                                    </div>
                                    <div className="mt-5">
                                        <div className="mt-2">
                                            <textarea
                                                rows={4}
                                                name="comment"
                                                id="comment"
                                                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                placeholder="I need 30 links for a tech website, can we talk about discount?"
                                                value={comment}
                                                onChange={(e) => {changComment(e.target.value);}}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                    <button
                                        type="button"
                                        className="w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                                        onClick={createRequest}
                                        disabled={isSending}
                                    >

                                        {isSending && <>
                                            <Spinner className="inline w-4 h-4 mb-1 mr-1 text-green-200 animate-spin fill-white"/>
                                            <span className="align-baseline">Processing...</span>
                                        </>}
                                        {!isSending && <>Make a request!</>}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                        onClick={cancelRequest}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
