import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Website from "../../classes/Website";
import {SubmitHandler, useForm} from "react-hook-form";
import {Spinner} from "../../components/Spinner";
import CountriesSelector from "./CountriesSelector";
import TopicsSelector from "./TopicsSelector";
import {AUTH0_AUDIENCE} from "../../config";
import {useAuth0} from "@auth0/auth0-react";
import {addWebsite, updateWebsite} from "../../services/websites";

interface IFormInput {
    id: number;
    name: string;
    geo: string[];
    topic: string[];
    organic: number;
    dr: number;
    acceptGambling: boolean;
    price: number;
}

export default function EditWebsiteModal(props: { onClose: () => void, onWebsiteModified: (isNew: boolean, website: Website) => void, website: Website|undefined, open:boolean }) {
    const {user, getAccessTokenSilently} = useAuth0();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const { register, getValues, setValue, handleSubmit } = useForm<IFormInput>({
        values: {
            id: props.website?.id || 0,
            name: props.website?.name || "",
            geo: props.website?.geo || [],
            topic: props.website?.topic || [],
            dr: props.website?.dr || 0,
            organic: props.website?.organic || 0,
            acceptGambling: props.website?.acceptGambling || false,
            price: props.website?.price || 0,
        }
    });
    function closeModal() {
        props.onClose();
    }

    const onSubmit: SubmitHandler<IFormInput> = (inputData) =>  {
        setIsUpdating(true);
        getAccessTokenSilently({
            authorizationParams: {
                audience: AUTH0_AUDIENCE
            }
        }).then((token) => {
            let website: Website = {
                id: inputData.id,
                name: inputData.name,
                geo: inputData.geo,
                topic: inputData.topic,
                organic: inputData.organic,
                dr: inputData.dr,
                acceptGambling: inputData.acceptGambling,
                price: inputData.price,
                isInMyFavorites: false
            };
            if (website.id === 0) {
                addWebsite(token, website).then((data) => {
                    if (data > 0) {
                        website.id = data;
                        props.onWebsiteModified(true, website);
                    }
                    else
                    {
                        alert("Some problems");
                    }
                    setIsUpdating(false);
                });
            }
            else {
                updateWebsite(token, website).then((data) => {
                    if (data) {
                        props.onWebsiteModified(false, website);
                    }
                    else
                    {
                        alert("Some problems");
                    }
                    setIsUpdating(false);
                });
            }
        });
    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                    Edit Website
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                                        onClick={closeModal}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <form onSubmit={handleSubmit(onSubmit)} className="mx-auto mt-16 max-w-xl sm:mt-20">
                                                <label htmlFor="email" className="block mt-2 text-sm font-medium leading-6 text-gray-900">
                                                    Website
                                                </label>
                                                <div className="mt-0.5">
                                                    <input
                                                        type="text"
                                                        {...register("name", { required: true, maxLength: 255 })}
                                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 rounded-md placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                        placeholder="website.com"
                                                    />
                                                </div>
                                                <label htmlFor="email" className="block mt-2 text-sm font-medium leading-6 text-gray-900">
                                                    GEO
                                                </label>
                                                <div className="mt-0.5">
                                                    <CountriesSelector countryCodes={getValues("geo")} updated={(geo) => setValue("geo", geo)}/>
                                                </div>
                                                <label htmlFor="email" className="block mt-2 text-sm font-medium leading-6 text-gray-900">
                                                    Topic
                                                </label>
                                                <div className="mt-0.5">
                                                    <TopicsSelector topicCodes={getValues("topic")} updated={(topic) => setValue("topic", topic)}/>
                                                </div>
                                                <label htmlFor="email" className="block mt-2 text-sm font-medium leading-6 text-gray-900">
                                                    Organic
                                                </label>
                                                <div className="mt-0.5">
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        max="999999999"
                                                        {...register("organic", {  required: true, maxLength: 255 })}
                                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <label htmlFor="email" className="block mt-2 text-sm font-medium leading-6 text-gray-900">
                                                    DR
                                                </label>
                                                <div className="mt-0.5">
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        max="999999999"
                                                        {...register("dr", {  required: true, maxLength: 255 })}
                                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                        placeholder="0"
                                                    />
                                                </div>
                                                <label htmlFor="email" className="block mt-2 text-sm font-medium leading-6 text-gray-900">
                                                    Price (€)
                                                </label>
                                                <div className="mt-0.5">
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        max="999999999"
                                                        {...register("price", {  required: true, maxLength: 255 })}
                                                        className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                        placeholder="100"
                                                    />
                                                </div>
                                                <div className="relative mt-4 pl-1 flex items-start">
                                                    <div className="flex h-6 items-center">
                                                        <input
                                                            type="checkbox"
                                                            id="acceptGambling"
                                                            {...register("acceptGambling")}
                                                            className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm leading-6">
                                                        <label htmlFor="acceptGambling" className="font-medium text-gray-900">
                                                            Accept Gambling
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                    <button
                                                        type="submit"
                                                        className="w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                                                        disabled={isUpdating}
                                                    >

                                                        {isUpdating && <>
                                                            <Spinner className="inline w-4 h-4 mb-1 mr-1 text-green-200 animate-spin fill-white"/>
                                                            <span className="align-baseline">Processing...</span>
                                                        </>}
                                                        {!isUpdating && <>Update</>}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                                        onClick={closeModal}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
