import React, {useEffect, useState} from 'react';
import {AUTH0_AUDIENCE} from "../../config";
import {useAuth0} from "@auth0/auth0-react";
import FavoriteWebsite from "../../classes/FavoriteWebsite";
import {getFavoritesWebsites} from "../../services/favoritesWebsites";
import {ArrowTopRightOnSquareIcon, CheckBadgeIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {Countries} from "../../enums/countries";
import {Topics} from "../../enums/topics";
import {DeselectButton} from "./DeselectButton";
import {FaceFrownIcon, MagnifyingGlassCircleIcon} from "@heroicons/react/24/outline";
import {Spinner} from "../../components/Spinner";
import {TablePagination} from "../../components/Table/TablePagination";

export default function Favorites() {
    const { getAccessTokenSilently } = useAuth0();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [favoritesWebsites, setFavoritesWebsites] = useState<FavoriteWebsite[]>([]);

    let fetchData = (page:number) => {
        if (!isFetching) {
            setIsFetching(true);
            setFavoritesWebsites([]);
            getAccessTokenSilently({
                authorizationParams: {
                    audience: AUTH0_AUDIENCE
                }
            }).then((token) => {
                getFavoritesWebsites(token, page).then((data) => {
                    setPageNumber(page);
                    setTotalRows(data.totalRows);
                    setFavoritesWebsites(data.favoritesWebsites);
                    setIsFetching(false);
                });
            });
        }
    };

    useEffect(() => {
        fetchData(pageNumber);
    }, []);

    function websiteDeselected(website: FavoriteWebsite) {
        let favoritesWebsitesNew = favoritesWebsites.filter(w => w.websiteId !== website.websiteId);
        setFavoritesWebsites([...favoritesWebsitesNew]);
    }

    return (
        <>
            <div className="pb-12">
                <div className="-mx-4 mt-8 sm:-mx-0">
                    <table className="min-w-full">
                        <thead className="sticky top-0 bg-white z-10 shadow-gray-300"
                               style={{boxShadow: "0px 0.5px 0px 0px rgb(209, 213, 219), 0px -0.5px 0px 0px rgb(209, 213, 219)"}}>
                            <tr>
                                <th scope="col"
                                    className="sm:py-3.5 py-6 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                    Website
                                </th>
                                <th scope="col"
                                    className="hidden w-72 min-w-full px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell">
                                    GEO
                                </th>
                                <th scope="col"
                                    className="hidden w-72 min-w-full px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:table-cell">
                                    Topic
                                </th>
                                <th scope="col"
                                    className="hidden w-28 min-w-full px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:table-cell">
                                    Organic
                                </th>
                                <th scope="col"
                                    className="hidden w-20 min-w-full px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:table-cell">
                                    DR
                                </th>
                                <th scope="col"
                                    className="hidden w-20 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:table-cell">
                                    Accept gambling
                                </th>
                                <th scope="col"
                                    className="hidden w-20 min-w-full px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:table-cell">
                                    Price
                                </th>
                                <th scope="col" className="w-28 min-w-full py-3.5 pl-3 pr-4">
                                    <span className="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {favoritesWebsites.map((website) => (
                                <tr key={website.websiteId}>
                                    <td className="w-full whitespace-nowrap max-w-0 py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                        {website.name}
                                        <a href={`http://${website.name}`} target="_blank">
                                            <ArrowTopRightOnSquareIcon
                                                className="w-4 h-4 ml-2 mb-0.5 inline text-gray-400 hover:text-gray-300"/>
                                        </a>

                                        <div className="lg:hidden">
                                            <div className="mt-1">
                                                {website.geo.map((geo) => (
                                                    <span className="inline-flex text-left gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                                    <img src={`/img/country/${Countries[geo]?.code || geo}.svg`}
                                                         alt={Countries[geo]?.name}
                                                         className="h-3 inline mt-0.5"
                                                    />
                                                        {Countries[geo]?.code}
                                                </span>
                                                ))}
                                            </div>
                                            <div className="mt-2 sm:hidden">
                                                {website.topic && website.topic.map((topic) => (
                                                    <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                                    {Topics[topic] !== null &&
                                                        <img src={`/img/topics/${Topics[topic]?.image}.png`}
                                                             alt={Topics[topic]?.image}
                                                             className="h-4 inline"
                                                        />
                                                    }
                                                        {Topics[topic] !== null ? Topics[topic]?.name : topic}
                                                </span>
                                                ))}
                                            </div>
                                        </div>

                                    </td>
                                    <td className="hidden px-3 py-1 text-sm text-center text-gray-500 lg:table-cell">{website.geo.map((geo) => (
                                        <span
                                            className="inline-flex text-left gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                            <img src={`/img/country/${Countries[geo]?.code || geo}.svg`}
                                                 alt={Countries[geo]?.name}
                                                 className="h-3 inline mt-0.5"
                                            />
                                            {Countries[geo]?.code}
                                        </span>
                                    ))}</td>
                                    <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">{website.topic && website.topic.map((topic) => (
                                        <span
                                            className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                            {Topics[topic] !== null &&
                                                <img src={`/img/topics/${Topics[topic]?.image}.png`}
                                                     alt={Topics[topic]?.image}
                                                     className="h-4 inline"
                                                />
                                            }
                                            {Topics[topic] !== null ? Topics[topic]?.name : topic}
                                        </span>
                                    ))}</td>
                                    <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">{website.organic}</td>
                                    <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">{website.dr}</td>
                                    <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">
                                        {website.acceptGambling &&
                                            <CheckBadgeIcon className="w-4 h-4 inline text-green-400"/>
                                        }
                                    </td>
                                    <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">
                                        €<span className="text-xl">{website.price}</span>
                                    </td>
                                    <td className="align-top py-1 pl-3 pr-4 text-right text-sm font-medium">
                                        <DeselectButton onWebsiteDeselected={(website) => {
                                            websiteDeselected(website);
                                        }} website={website}/>
                                        <div className="w-28 text-center mt-3 truncate text-gray-500 sm:hidden">
                                            €<span className="text-xl">{website.price}</span>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {isFetching &&
                        <div role="status" className="py-20 text-center">
                            <Spinner className="inline w-10 h-10 mr-2 text-gray-200 animate-spin fill-green-600"/>
                        </div>
                    }
                    {favoritesWebsites.length === 0 && !isFetching &&
                        <div className="text-center">
                            <FaceFrownIcon className="mx-auto my-5 h-12 w-12 text-gray-300"/>
                            <h3 className="mt-2 text-sm font-semibold text-gray-900">No selected websites</h3>
                            <p className="mt-1 text-sm text-gray-500">Get started by selecting websites and creating a new request.</p>
                            <div className="mt-6">
                                <Link
                                    to="/"
                                    type="button"
                                    className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                >
                                    <MagnifyingGlassCircleIcon className="w-6 h-6 mr-3"/>
                                    Back to Marketplace
                                </Link>
                            </div>
                        </div>
                    }
                    <TablePagination pageNumber={pageNumber} totalRows={totalRows} rowsPerPage={25} onGoToPage={(page) => fetchData(page)}/>
                </div>
            </div>
        </>
    );
}
