import {API_URL} from "../config";
import UserRequestCreate from "../classes/UserRequestCreate";
import RequestsFilter from "../classes/RequestsFilter";

export function getUserRequests(token:string, pageNumber:number, filter:RequestsFilter) {
    let queryString = new URLSearchParams({pageNumber: `${pageNumber}`}).toString();
    return fetch(`${API_URL}/requests/search?${queryString}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(filter)
    }).then(response => {
        return response.json();
    });
}

export function addUserRequest(token:string, request:UserRequestCreate) {
    return fetch(`${API_URL}/requests`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    }).then(response => {
        return response.json();
    });
}