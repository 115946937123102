import {useAuth0} from "@auth0/auth0-react";
import React, {useState} from "react";
import {AUTH0_AUDIENCE} from "../../config";
import {Spinner} from "../../components/Spinner";
import FavoriteWebsite from "../../classes/FavoriteWebsite";
import {deleteFavoriteWebsite} from "../../services/favoritesWebsites";

export function DeselectButton(props: { onWebsiteDeselected: (website: FavoriteWebsite) => void, website: FavoriteWebsite }) {
    const {user, getAccessTokenSilently} = useAuth0();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [website, setWebsite] = useState<FavoriteWebsite>(props.website);

    function buttonClick() {
        setIsLoading(true);
        getAccessTokenSilently({
            authorizationParams: {
                audience: AUTH0_AUDIENCE
            }
        }).then((token) => {
            deleteFavoriteWebsite(token, website.websiteId).then((data) => {
                setIsLoading(false);
                props.onWebsiteDeselected(website);
            });
        });
    }
    return <button
        type="button"
        className="w-full text-center rounded-md bg-gray-400 p-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-gray-200"
        onClick={buttonClick}
        disabled={isLoading}
    >
        {isLoading && <>
            <Spinner className="inline w-4 h-4 mb-1 mr-1 text-gray-200 animate-spin fill-white"/>
            <span className="align-baseline">Deselecting...</span>
        </>}
        {!isLoading && <>Deselect<span className="sr-only">, {website.name}</span></>}
    </button>;
}