import {API_URL} from "../config";
import WebsitesFilter from "../classes/WebsitesFilter";
import UserRequestCreate from "../classes/UserRequestCreate";
import Website from "../classes/Website";
import axios from "axios";

export function getWebsites(token:string, pageNumber:number, websitesFilter:WebsitesFilter) {
    let queryString = new URLSearchParams({pageNumber: `${pageNumber}`}).toString();
    return fetch(`${API_URL}/websites/search?${queryString}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(websitesFilter)
    }).then(response => {
        return response.json();
    });
}

export function addWebsite(token:string, website:Website) {
    return fetch(`${API_URL}/websites/create`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(website)
    }).then(response => {
        return response.json();
    });
}


export function updateWebsite(token:string, website:Website) {
    return fetch(`${API_URL}/websites/update`, {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(website)
    }).then(response => {
        return response.json();
    });
}


export function deleteWebsite(token:string, id:number) {
    let queryString = new URLSearchParams({id: `${id}`}).toString();
    return fetch(`${API_URL}/websites/delete?${queryString}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        }
    }).then(response => {
        return response.json();
    });
}

export function uploadWebsites(token:string, file:File) {
    return axios.postForm(`${API_URL}/websites/upload`, {
        "file": file
    },{
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        }
    }).then(response => {
        return response.data;
    });
}