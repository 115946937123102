import {Fragment, useState} from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import {useAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";
import MenuLink from "./MenuLink";
import MenuLinkMobile from "./MenuLinkMobile";
import UserPermissions from "../../classes/UserPermissions";
import ContactUsModal from "../ContactUsModal";
import SuccessModal from "../SuccessModal";
function classNames(...classes:any[]) {
    return classes.filter(Boolean).join(' ')
}
export default function TopMenu(props:{userPermissions: UserPermissions|undefined;}) {
    const {logout, user } = useAuth0();
    const [isContatUsModalOpened, setIsContatUsModalOpened] = useState(false);
    const displaySelections = props.userPermissions == undefined || !props.userPermissions.requestsModify;
    const displayRequests = props.userPermissions !== undefined && (props.userPermissions.requestsRead || props.userPermissions.requestsModify);
    const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);

    function contactUsModalClose(result:boolean) {
        setIsContatUsModalOpened(false);
        if (result) {
            successModalOpen();
        }
    }

    function contactUsModalOpen() {
        setIsContatUsModalOpened(true);
    }

    function successModalOpen() {
        setIsSuccessModalOpened(true);
    }

    function successModalClose() {
        setIsSuccessModalOpened(false);
    }

    return (
        <>
            {!displayRequests &&
                <div className="sticky top-0 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-black z-50">
                    <button
                        type="button"
                        className="absolute top-3.5 right-6 lg:right-10 inline-flex items-center gap-x-1.5 rounded-md bg-green-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 outline outline-2 outline-offset-2 outline-green-800"
                        onClick={contactUsModalOpen}
                    >
                        Contact Us!
                    </button>
                </div>
            }
            {isContatUsModalOpened &&
                <ContactUsModal onClose={contactUsModalClose}/>
            }
            {isSuccessModalOpened &&
                <SuccessModal onClose={successModalClose}
                              header="Sent!"
                              text="We will get back to you shortly!"
                              buttonText="Back to Marketplace"
                />
            }
            <Disclosure as="nav" className="bg-white shadow">
                {({ open }) => (
                    <>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <div className="flex h-16 justify-between">
                                <div className="flex">
                                    <div className="-ml-2 mr-2 flex items-center md:hidden">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                                            <span className="absolute -inset-0.5" />
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                    <div className="flex flex-shrink-0 items-center">
                                        <Link to="/">
                                            <img className="h-11 w-auto" src="logo.png" alt="RankingFrog" />
                                        </Link>
                                    </div>
                                    <div className="hidden md:ml-6 md:flex md:space-x-8">
                                        <MenuLink to="/">Marketplace</MenuLink>
                                        {displaySelections && <MenuLink to="/favorites">My Selections</MenuLink>}
                                        {displayRequests && <MenuLink to="/requests">Requests</MenuLink>}
                                        {displayRequests && <MenuLink to="/websites-searches">Searches</MenuLink>}
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <div className="hidden mr-32 md:flex md:flex-shrink-0 md:items-center">
                                        {/* Profile dropdown */}
                                        <Menu as="div" className="relative ml-3">
                                            <div>
                                                <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
                                                    <span className="absolute -inset-1.5" />
                                                    <span className="sr-only">Open user menu</span>
                                                    <img
                                                        className="h-8 w-8 rounded-full"
                                                        src={user?.picture}
                                                        alt={user?.name}
                                                    />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-200"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link
                                                                to="/profile"
                                                                className={classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                            >
                                                                Your Profile
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <a
                                                                href="#"
                                                                className={classNames(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block px-4 py-2 text-sm text-gray-700'
                                                                )}
                                                                onClick={()=> logout({ logoutParams: { returnTo: window.location.origin } })}
                                                            >
                                                                Sign out
                                                            </a>
                                                        )}
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="md:hidden">
                            <div className="space-y-1 pb-3 pt-2">
                                <MenuLinkMobile to="/">Marketplace</MenuLinkMobile>
                                <MenuLinkMobile to="/favorites">My Selections</MenuLinkMobile>
                            </div>
                            <div className="border-t border-gray-200 pb-3 pt-4">
                                <div className="flex items-center px-4 sm:px-6">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-10 w-10 rounded-full"
                                            src={user?.picture}
                                            alt={user?.name}
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-gray-800">{user?.name}</div>
                                        <div className="text-sm font-medium text-gray-500">{user?.email}</div>
                                    </div>
                                </div>
                                <div className="mt-3 space-y-1">
                                    <Disclosure.Button
                                        as={Link}
                                        to="/profile"
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                                    >
                                        Your Profile
                                    </Disclosure.Button>
                                    <Disclosure.Button
                                        as="a"
                                        href="#"
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                                        onClick={()=> logout({ logoutParams: { returnTo: window.location.origin } })}
                                    >
                                        Sign out
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </>
    );
}