import {MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {XMarkIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";

export default function StringSearchSelector(
    props:{
        name:string,
        value:string,
        updated:(value:string) => void,
        search:() => void
    }
) {
    const [value, setValue] = useState<string>(props.value);

    useEffect(() => {
        valueUpdated();
    }, [value])

    function valueUpdated() {
        props.updated(value);
    }

    function updateValue(value:string) {
        setValue(value)
    }
    function resetValues() {
        setValue("");
        props.search();
    }

    function handleKeyDown(key: string) {
        if (key.toLowerCase() === "enter")
            props.search();
    }

    return (
        <div className="flex">
            <input
                type="search"
                name={`${props.name}`}
                id={`${props.name}`}
                className="inline-flex grow rounded-l-md px-3 border-0 text-sm text-gray-900  ring-1 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-200"
                placeholder="Search..."
                value={value}
                onChange={(e) => { updateValue(e.target.value) }}
                onKeyDown={(e) => {handleKeyDown(e.key)}}
            />
            <button
                type="button"
                className="inline-flex gap-x-1.5 bg-frog-200 -ml-px p-2 text-sm font-semibold text-white shadow-sm hover:bg-frog-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-frog-200"
                onClick={props.search}
            >
                <MagnifyingGlassIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
            </button>
            <button
                type="button"
                className="inline-flex gap-x-1.5 rounded-r-md bg-gray-300 p-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300"
                onClick={resetValues}
            >
                <XMarkIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
            </button>
        </div>
    );
}