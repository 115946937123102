import Website from "../../classes/Website";
import {useAuth0} from "@auth0/auth0-react";
import React, {useState} from "react";
import {AUTH0_AUDIENCE} from "../../config";
import {Spinner} from "../../components/Spinner";
import {deleteWebsite} from "../../services/websites";

export function DeleteButton(props: { onWebsiteDeleted: (website: Website) => void, website: Website }) {
    const {user, getAccessTokenSilently} = useAuth0();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    function buttonClick() {
        setIsDeleting(true);
        getAccessTokenSilently({
            authorizationParams: {
                audience: AUTH0_AUDIENCE
            }
        }).then((token) => {
            deleteWebsite(token, props.website.id).then((data) => {
                setIsDeleting(false);
                if (data) {
                    props.onWebsiteDeleted(props.website);
                }
                else {
                    alert("Some problem");
                }
            });
        });
    }

    return <button
        type="button"
        className="text-center rounded-md bg-red-600 p-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-red-400"
        onClick={buttonClick}
        disabled={isDeleting}
    >
        {isDeleting && <>
            <Spinner className="inline w-4 h-4 mb-1 mr-1 text-red-200 animate-spin fill-white"/>
            <span className="align-baseline">Deleting...</span>
        </>}
        {!isDeleting && <>Delete</>}
    </button>;
}