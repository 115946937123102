import React, {useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {AUTH0_AUDIENCE} from "../../config";
import {updateUserProfile} from "../../services/userProfiles";
import UpdateUserProfileRequest from "../../classes/UpdateUserProfileRequest";
import SuccessModal from "../../components/SuccessModal";
import {Spinner} from "../../components/Spinner";
import {SubmitHandler, useForm} from "react-hook-form";

interface IFormInput {
    firstName: string|undefined;
    lastName: string|undefined;
}

export default function Profile() {
    const {user, getAccessTokenSilently} = useAuth0();
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
    const { register, handleSubmit } = useForm<IFormInput>({
        values: {firstName: user?.given_name, lastName: user?.family_name}
    });
    const onSubmit: SubmitHandler<IFormInput> = (inputData) => {
        setIsUpdating(true);
        getAccessTokenSilently({
            authorizationParams: {
                audience: AUTH0_AUDIENCE
            }
        }).then((token) => {
            let request: UpdateUserProfileRequest = {
                firstName: inputData.firstName,
                lastName: inputData.lastName
            };
            updateUserProfile(token, request).then((data) => {
                if (user !== undefined) {
                    user.given_name = inputData.firstName;
                    user.family_name = inputData.lastName;
                }
                setIsUpdating(false);
                setIsSuccessModalOpened(true);
            });
        });
    }
    function successModalClose() {
        setIsSuccessModalOpened(false);
    }

    return (
        <>
            {isSuccessModalOpened &&
                <SuccessModal header="Updated!"
                              text="Your profile was successfully updated"
                              buttonText="Close"
                              onClose={successModalClose}
                />
            }
            <div className="isolate px-6 py-24 sm:py-32 lg:px-8">
                <div className="mx-auto max-w-2xl text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Profile</h2>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="mx-auto mt-16 max-w-xl sm:mt-20">
                    <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                        <div>
                            <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                First name
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    autoComplete="given-name"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                    {...register("firstName", { required: true, maxLength: 255 })}
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                Last name
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="text"
                                    autoComplete="family-name"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                    {...register("lastName", { required: true, maxLength: 255 })}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-2">
                            <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                Email
                            </label>
                            <div className="mt-2.5">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    autoComplete="email"
                                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                    value={user?.email}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <button
                            type="submit"
                            className="block w-full rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            disabled={isUpdating}
                        >
                            {isUpdating && <>
                                <Spinner className="inline w-4 h-4 mb-1 mr-1 text-green-200 animate-spin fill-white"/>
                                <span className="align-baseline">Processing...</span>
                            </>}
                            {!isUpdating && <>Save</>}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
}
