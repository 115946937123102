import React, {useEffect, useState} from 'react';
import {AUTH0_AUDIENCE} from "../../config";
import {useAuth0} from "@auth0/auth0-react";
import moment from "moment";
import {FaceFrownIcon} from "@heroicons/react/24/outline";
import {TablePagination} from "../../components/Table/TablePagination";
import {Spinner} from "../../components/Spinner";
import {SortByColumnButton} from "../../components/Table/SortByColumnButton";
import StringSearchSelector from "../Marketplace/StringSearchSelector";
import WebsitesSearchesFilter from "../../classes/WebsitesSearchesFilter";
import WebsitesSearch from "../../classes/WebsitesSearch";
import {getWebsitesSearches} from "../../services/websitesSearches";
import {Countries} from "../../enums/countries";
import {Topics} from "../../enums/topics";
import {CheckBadgeIcon, StopCircleIcon} from "@heroicons/react/20/solid";


export default function WebsitesSearches() {
    const { getAccessTokenSilently } = useAuth0();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [filter, setFilter] = useState<WebsitesSearchesFilter>({
        email: "",
        sortColumn: "date",
        sortAsc: false
    });
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [websitesSearches, setWebsitesSearches] = useState<WebsitesSearch[]>([]);

    let fetchData = (page:number) => {
        if (!isFetching) {
            setIsFetching(true);
            setWebsitesSearches([]);
            getAccessTokenSilently({
                authorizationParams: {
                    audience: AUTH0_AUDIENCE
                }
            }).then((token) => {
                getWebsitesSearches(token, page, filter).then((data) => {
                    setPageNumber(page);
                    setTotalRows(data.totalRows);
                    setWebsitesSearches(data.websitesSearches);
                    setIsFetching(false);
                });
            });
        }
    };
    useEffect(() => {
        filterData();
    }, []);

    function updateSort(name: string) {
        if (filter.sortColumn === name)
            filter.sortAsc = !filter.sortAsc;
        else {
            filter.sortColumn = name;
            filter.sortAsc = true;
        }
        filterData();

        return undefined;
    }

    function updateEmailFilter(value: string) {
        filter.email = value;
    }

     function filterData() {
        setFilter(filter);
        setPageNumber(1);
        setWebsitesSearches([]);
        fetchData(1);
    }

    return (
        <>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 mt-10">
                <div>
                    <label htmlFor="emailFilter" className="block text-xs font-medium leading-6 text-gray-600">
                        Email
                    </label>
                    <StringSearchSelector
                        name="emailFilter"
                        value={filter.email}
                        updated={updateEmailFilter}
                        search={filterData}
                    />
                </div>
            </div>
            <div className="pb-12">
                <div className="-mx-4 mt-8 sm:-mx-0">
                    <table className="min-w-full">
                        <thead className="sticky top-0 bg-white z-10 shadow-gray-300"
                            style={{boxShadow: "0px 0.5px 0px 0px rgb(209, 213, 219), 0px -0.5px 0px 0px rgb(209, 213, 219)"}}>
                            <tr>
                                <th scope="col" className="sm:w-60 sm:py-3.5 py-6 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                    Email
                                    <SortByColumnButton columnName="email"
                                                        sortColumn={filter.sortColumn}
                                                        sortAsc={filter.sortAsc}
                                                        onClick={() => {
                                                            updateSort("email");
                                                        }}/>
                                </th>
                                <th scope="col"
                                    className="min-w-full px-3 py-3.5 text-sm text-left font-semibold text-gray-900">
                                    Search Filter
                                </th>
                                <th scope="col" className="w-28 px-3 py-3.5 text-sm text-center font-semibold text-gray-900 sm:table-cell">
                                    Date
                                    <SortByColumnButton columnName="date"
                                                        sortColumn={filter.sortColumn}
                                                        sortAsc={filter.sortAsc}
                                                        onClick={() => {
                                                            updateSort("date");
                                                        }}/>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {websitesSearches.map((websitesSearch) => (
                                <tr key={websitesSearch.id}>
                                    <td className="py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                        <div>{websitesSearch.email}</div>
                                        <div className="mt-1 text-gray-700">{websitesSearch.firstName} {websitesSearch.lastName}</div>
                                    </td>
                                    <td className="px-3 py-1 text-sm text-gray-500">
                                        {websitesSearch.geo && websitesSearch.geo.length > 0 &&
                                            <div>
                                                <span className="pr-1">GEO:</span>
                                                    {websitesSearch.geo.map((geo) => (
                                                        <span className="inline-flex text-left gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                                        <img src={`/img/country/${Countries[geo]?.code || geo}.svg`}
                                                             alt={Countries[geo]?.name}
                                                             className="h-3 inline mt-0.5"
                                                        />
                                                            {Countries[geo]?.code}
                                                    </span>
                                                    ))}
                                            </div>
                                        }
                                        {websitesSearch.topic && websitesSearch.topic.length > 0 &&
                                            <div>
                                                <span className="pr-1">Topic:</span>
                                                    {websitesSearch.topic && websitesSearch.topic.map((topic) => (
                                                        <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                                        {Topics[topic] !== null &&
                                                            <img src={`/img/topics/${Topics[topic]?.image}.png`}
                                                                 alt={Topics[topic]?.image}
                                                                 className="h-4 inline"
                                                            />
                                                        }{Topics[topic] !== null ? Topics[topic]?.name : topic}
                                                        </span>
                                                    ))}
                                            </div>
                                        }

                                        {websitesSearch.acceptGambling != null &&
                                            <div>
                                                <span className="pr-1">Accept gambling:</span>
                                                {websitesSearch.acceptGambling === true &&
                                                    <CheckBadgeIcon className="w-4 h-4 inline text-green-400"/>
                                                }
                                                {websitesSearch.acceptGambling === false &&
                                                    <StopCircleIcon className="w-4 h-4 inline text-red-400"/>
                                                }
                                            </div>
                                        }
                                        {(websitesSearch.priceFrom != null || websitesSearch.priceTo != null) &&
                                            <div>
                                                Price from {websitesSearch.priceFrom == null ? "..." : websitesSearch.priceFrom}
                                                &nbsp;to {websitesSearch.priceTo == null ? "..." : websitesSearch.priceTo}
                                            </div>
                                        }
                                        {(websitesSearch.organicFrom != null || websitesSearch.organicTo != null) &&
                                            <div>
                                                DR from {websitesSearch.organicFrom == null ? "..." : websitesSearch.organicFrom}
                                                &nbsp;to {websitesSearch.organicTo == null ? "..." : websitesSearch.organicTo}
                                            </div>
                                        }
                                        {(websitesSearch.drFrom != null || websitesSearch.drTo != null) &&
                                            <div>
                                                DR from {websitesSearch.drFrom == null ? "..." : websitesSearch.drFrom}
                                                &nbsp;to {websitesSearch.drTo == null ? "..." : websitesSearch.drTo}
                                            </div>
                                        }

                                        {(websitesSearch.website != null && websitesSearch.website.length > 0) &&
                                            <div>
                                                Name is like "{websitesSearch.website}"
                                            </div>
                                        }
                                    </td>
                                    <td className="px-3 py-1 pr-4 text-sm text-center text-gray-500">{moment(websitesSearch.createdOn).format('YYYY-MM-DD HH:mm')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {isFetching &&
                        <div role="status" className="py-20 text-center">
                            <Spinner className="inline w-10 h-10 mr-2 text-gray-200 animate-spin fill-green-600"/>
                        </div>
                    }
                    {websitesSearches.length == 0 && !isFetching &&
                        <div className="text-center">
                            <FaceFrownIcon className="mx-auto my-5 h-12 w-12 text-gray-300"/>
                            <h3 className="mt-2 text-sm font-semibold text-gray-900">No user requests found</h3>
                        </div>
                    }
                    <TablePagination pageNumber={pageNumber} totalRows={totalRows} rowsPerPage={25} onGoToPage={(page) => fetchData(page)}/>
                </div>
            </div>
        </>
    );
}
