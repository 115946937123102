import {Link, useLocation} from "react-router-dom";
import {JSXElementConstructor, ReactElement} from "react";
import {Disclosure} from "@headlessui/react";

interface MenuLinkMobileProps {
    children: string | ReactElement<any, string | JSXElementConstructor<any>>;
    to: string;
}
export default function MenuLinkMobile(props:MenuLinkMobileProps){

    const location = useLocation();
    const active = location.pathname == props.to;
    const classNames = getClasses(active);

    return (
        <Disclosure.Button as={Link} to={props.to} className={classNames}>{props.children}</Disclosure.Button>
    );
}

function getClasses(active:boolean){
    if (active)
        return "block border-l-4 border-green-500 bg-green-50 py-2 pl-3 pr-4 text-base font-medium text-green-700 sm:pl-5 sm:pr-6";
    return "block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700 sm:pl-5 sm:pr-6";
}