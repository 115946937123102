export interface Country {
    code: string;
    name: string;
}

export let Countries: { [code: string] : Country; } = {};
Countries["EN"] = {code: "EN", name: "English"};
Countries["AF"] = {code: "AF", name: "Afghanistan"};
Countries["AX"] = {code: "AX", name: "Åland Islands"};
Countries["AL"] = {code: "AL", name: "Albania"};
Countries["DZ"] = {code: "DZ", name: "Algeria"};
Countries["AS"] = {code: "AS", name: "American Samoa"};
Countries["AD"] = {code: "AD", name: "AndorrA"};
Countries["AO"] = {code: "AO", name: "Angola"};
Countries["AI"] = {code: "AI", name: "Anguilla"};
Countries["AQ"] = {code: "AQ", name: "Antarctica"};
Countries["AG"] = {code: "AG", name: "Antigua and Barbuda"};
Countries["AR"] = {code: "AR", name: "Argentina"};
Countries["AM"] = {code: "AM", name: "Armenia"};
Countries["AW"] = {code: "AW", name: "Aruba"};
Countries["AU"] = {code: "AU", name: "Australia"};
Countries["AT"] = {code: "AT", name: "Austria"};
Countries["AZ"] = {code: "AZ", name: "Azerbaijan"};
Countries["BS"] = {code: "BS", name: "Bahamas"};
Countries["BH"] = {code: "BH", name: "Bahrain"};
Countries["BD"] = {code: "BD", name: "Bangladesh"};
Countries["BB"] = {code: "BB", name: "Barbados"};
Countries["BY"] = {code: "BY", name: "Belarus"};
Countries["BE"] = {code: "BE", name: "Belgium"};
Countries["BZ"] = {code: "BZ", name: "Belize"};
Countries["BJ"] = {code: "BJ", name: "Benin"};
Countries["BM"] = {code: "BM", name: "Bermuda"};
Countries["BT"] = {code: "BT", name: "Bhutan"};
Countries["BO"] = {code: "BO", name: "Bolivia"};
Countries["BA"] = {code: "BA", name: "Bosnia and Herzegovina"};
Countries["BW"] = {code: "BW", name: "Botswana"};
Countries["BV"] = {code: "BV", name: "Bouvet Island"};
Countries["BR"] = {code: "BR", name: "Brazil"};
Countries["IO"] = {code: "IO", name: "British Indian Ocean Territory"};
Countries["BN"] = {code: "BN", name: "Brunei Darussalam"};
Countries["BG"] = {code: "BG", name: "Bulgaria"};
Countries["BF"] = {code: "BF", name: "Burkina Faso"};
Countries["BI"] = {code: "BI", name: "Burundi"};
Countries["KH"] = {code: "KH", name: "Cambodia"};
Countries["CM"] = {code: "CM", name: "Cameroon"};
Countries["CA"] = {code: "CA", name: "Canada"};
Countries["CV"] = {code: "CV", name: "Cape Verde"};
Countries["KY"] = {code: "KY", name: "Cayman Islands"};
Countries["CF"] = {code: "CF", name: "Central African Republic"};
Countries["TD"] = {code: "TD", name: "Chad"};
Countries["CL"] = {code: "CL", name: "Chile"};
Countries["CN"] = {code: "CN", name: "China"};
Countries["CX"] = {code: "CX", name: "Christmas Island"};
Countries["CC"] = {code: "CC", name: "Cocos (Keeling) Islands"};
Countries["CO"] = {code: "CO", name: "Colombia"};
Countries["KM"] = {code: "KM", name: "Comoros"};
Countries["CG"] = {code: "CG", name: "Congo"};
Countries["CD"] = {code: "CD", name: "Congo, The Democratic Republic of the"};
Countries["CK"] = {code: "CK", name: "Cook Islands"};
Countries["CR"] = {code: "CR", name: "Costa Rica"};
Countries["CI"] = {code: "CI", name: "Cote D\"Ivoire"};
Countries["HR"] = {code: "HR", name: "Croatia"};
Countries["CU"] = {code: "CU", name: "Cuba"};
Countries["CY"] = {code: "CY", name: "Cyprus"};
Countries["CZ"] = {code: "CZ", name: "Czech Republic"};
Countries["DK"] = {code: "DK", name: "Denmark"};
Countries["DJ"] = {code: "DJ", name: "Djibouti"};
Countries["DM"] = {code: "DM", name: "Dominica"};
Countries["DO"] = {code: "DO", name: "Dominican Republic"};
Countries["EC"] = {code: "EC", name: "Ecuador"};
Countries["EG"] = {code: "EG", name: "Egypt"};
Countries["SV"] = {code: "SV", name: "El Salvador"};
Countries["GQ"] = {code: "GQ", name: "Equatorial Guinea"};
Countries["ER"] = {code: "ER", name: "Eritrea"};
Countries["EE"] = {code: "EE", name: "Estonia"};
Countries["ET"] = {code: "ET", name: "Ethiopia"};
Countries["FK"] = {code: "FK", name: "Falkland Islands (Malvinas)"};
Countries["FO"] = {code: "FO", name: "Faroe Islands"};
Countries["FJ"] = {code: "FJ", name: "Fiji"};
Countries["FI"] = {code: "FI", name: "Finland"};
Countries["FR"] = {code: "FR", name: "France"};
Countries["GF"] = {code: "GF", name: "French Guiana"};
Countries["PF"] = {code: "PF", name: "French Polynesia"};
Countries["TF"] = {code: "TF", name: "French Southern Territories"};
Countries["GA"] = {code: "GA", name: "Gabon"};
Countries["GM"] = {code: "GM", name: "Gambia"};
Countries["GE"] = {code: "GE", name: "Georgia"};
Countries["DE"] = {code: "DE", name: "Germany"};
Countries["GH"] = {code: "GH", name: "Ghana"};
Countries["GI"] = {code: "GI", name: "Gibraltar"};
Countries["GR"] = {code: "GR", name: "Greece"};
Countries["GL"] = {code: "GL", name: "Greenland"};
Countries["GD"] = {code: "GD", name: "Grenada"};
Countries["GP"] = {code: "GP", name: "Guadeloupe"};
Countries["GU"] = {code: "GU", name: "Guam"};
Countries["GT"] = {code: "GT", name: "Guatemala"};
Countries["GG"] = {code: "GG", name: "Guernsey"};
Countries["GN"] = {code: "GN", name: "Guinea"};
Countries["GW"] = {code: "GW", name: "Guinea-Bissau"};
Countries["GY"] = {code: "GY", name: "Guyana"};
Countries["HT"] = {code: "HT", name: "Haiti"};
Countries["HM"] = {code: "HM", name: "Heard Island and Mcdonald Islands"};
Countries["VA"] = {code: "VA", name: "Holy See (Vatican City State)"};
Countries["HN"] = {code: "HN", name: "Honduras"};
Countries["HK"] = {code: "HK", name: "Hong Kong"};
Countries["HU"] = {code: "HU", name: "Hungary"};
Countries["IS"] = {code: "IS", name: "Iceland"};
Countries["IN"] = {code: "IN", name: "India"};
Countries["ID"] = {code: "ID", name: "Indonesia"};
Countries["IR"] = {code: "IR", name: "Iran, Islamic Republic Of"};
Countries["IQ"] = {code: "IQ", name: "Iraq"};
Countries["IE"] = {code: "IE", name: "Ireland"};
Countries["IM"] = {code: "IM", name: "Isle of Man"};
Countries["IL"] = {code: "IL", name: "Israel"};
Countries["IT"] = {code: "IT", name: "Italy"};
Countries["JM"] = {code: "JM", name: "Jamaica"};
Countries["JP"] = {code: "JP", name: "Japan"};
Countries["JE"] = {code: "JE", name: "Jersey"};
Countries["JO"] = {code: "JO", name: "Jordan"};
Countries["KZ"] = {code: "KZ", name: "Kazakhstan"};
Countries["KE"] = {code: "KE", name: "Kenya"};
Countries["KI"] = {code: "KI", name: "Kiribati"};
Countries["KP"] = {code: "KP", name: "Korea, Democratic People\"S Republic of"};
Countries["KR"] = {code: "KR", name: "Korea, Republic of"};
Countries["KW"] = {code: "KW", name: "Kuwait"};
Countries["KG"] = {code: "KG", name: "Kyrgyzstan"};
Countries["LA"] = {code: "LA", name: "Lao People\"S Democratic Republic"};
Countries["LV"] = {code: "LV", name: "Latvia"};
Countries["LB"] = {code: "LB", name: "Lebanon"};
Countries["LS"] = {code: "LS", name: "Lesotho"};
Countries["LR"] = {code: "LR", name: "Liberia"};
Countries["LY"] = {code: "LY", name: "Libyan Arab Jamahiriya"};
Countries["LI"] = {code: "LI", name: "Liechtenstein"};
Countries["LT"] = {code: "LT", name: "Lithuania"};
Countries["LU"] = {code: "LU", name: "Luxembourg"};
Countries["MO"] = {code: "MO", name: "Macao"};
Countries["MK"] = {code: "MK", name: "Macedonia, The Former Yugoslav Republic of"};
Countries["MG"] = {code: "MG", name: "Madagascar"};
Countries["MW"] = {code: "MW", name: "Malawi"};
Countries["MY"] = {code: "MY", name: "Malaysia"};
Countries["MV"] = {code: "MV", name: "Maldives"};
Countries["ML"] = {code: "ML", name: "Mali"};
Countries["MT"] = {code: "MT", name: "Malta"};
Countries["MH"] = {code: "MH", name: "Marshall Islands"};
Countries["MQ"] = {code: "MQ", name: "Martinique"};
Countries["MR"] = {code: "MR", name: "Mauritania"};
Countries["MU"] = {code: "MU", name: "Mauritius"};
Countries["YT"] = {code: "YT", name: "Mayotte"};
Countries["MX"] = {code: "MX", name: "Mexico"};
Countries["FM"] = {code: "FM", name: "Micronesia, Federated States of"};
Countries["MD"] = {code: "MD", name: "Moldova, Republic of"};
Countries["MC"] = {code: "MC", name: "Monaco"};
Countries["MN"] = {code: "MN", name: "Mongolia"};
Countries["ME"] = {code: "ME", name: "Montenegro"};
Countries["MS"] = {code: "MS", name: "Montserrat"};
Countries["MA"] = {code: "MA", name: "Morocco"};
Countries["MZ"] = {code: "MZ", name: "Mozambique"};
Countries["MM"] = {code: "MM", name: "Myanmar"};
Countries["NA"] = {code: "NA", name: "Namibia"};
Countries["NR"] = {code: "NR", name: "Nauru"};
Countries["NP"] = {code: "NP", name: "Nepal"};
Countries["NL"] = {code: "NL", name: "Netherlands"};
Countries["NC"] = {code: "NC", name: "New Caledonia"};
Countries["NZ"] = {code: "NZ", name: "New Zealand"};
Countries["NI"] = {code: "NI", name: "Nicaragua"};
Countries["NE"] = {code: "NE", name: "Niger"};
Countries["NG"] = {code: "NG", name: "Nigeria"};
Countries["NU"] = {code: "NU", name: "Niue"};
Countries["NF"] = {code: "NF", name: "Norfolk Island"};
Countries["MP"] = {code: "MP", name: "Northern Mariana Islands"};
Countries["NO"] = {code: "NO", name: "Norway"};
Countries["OM"] = {code: "OM", name: "Oman"};
Countries["PK"] = {code: "PK", name: "Pakistan"};
Countries["PW"] = {code: "PW", name: "Palau"};
Countries["PS"] = {code: "PS", name: "Palestinian Territory, Occupied"};
Countries["PA"] = {code: "PA", name: "Panama"};
Countries["PG"] = {code: "PG", name: "Papua New Guinea"};
Countries["PY"] = {code: "PY", name: "Paraguay"};
Countries["PE"] = {code: "PE", name: "Peru"};
Countries["PH"] = {code: "PH", name: "Philippines"};
Countries["PN"] = {code: "PN", name: "Pitcairn"};
Countries["PL"] = {code: "PL", name: "Poland"};
Countries["PT"] = {code: "PT", name: "Portugal"};
Countries["PR"] = {code: "PR", name: "Puerto Rico"};
Countries["QA"] = {code: "QA", name: "Qatar"};
Countries["RE"] = {code: "RE", name: "Reunion"};
Countries["RO"] = {code: "RO", name: "Romania"};
Countries["RU"] = {code: "RU", name: "Russian Federation"};
Countries["RW"] = {code: "RW", name: "RWANDA"};
Countries["SH"] = {code: "SH", name: "Saint Helena"};
Countries["KN"] = {code: "KN", name: "Saint Kitts and Nevis"};
Countries["LC"] = {code: "LC", name: "Saint Lucia"};
Countries["PM"] = {code: "PM", name: "Saint Pierre and Miquelon"};
Countries["VC"] = {code: "VC", name: "Saint Vincent and the Grenadines"};
Countries["WS"] = {code: "WS", name: "Samoa"};
Countries["SM"] = {code: "SM", name: "San Marino"};
Countries["ST"] = {code: "ST", name: "Sao Tome and Principe"};
Countries["SA"] = {code: "SA", name: "Saudi Arabia"};
Countries["SN"] = {code: "SN", name: "Senegal"};
Countries["RS"] = {code: "RS", name: "Serbia"};
Countries["SC"] = {code: "SC", name: "Seychelles"};
Countries["SL"] = {code: "SL", name: "Sierra Leone"};
Countries["SG"] = {code: "SG", name: "Singapore"};
Countries["SK"] = {code: "SK", name: "Slovakia"};
Countries["SI"] = {code: "SI", name: "Slovenia"};
Countries["SB"] = {code: "SB", name: "Solomon Islands"};
Countries["SO"] = {code: "SO", name: "Somalia"};
Countries["ZA"] = {code: "ZA", name: "South Africa"};
Countries["GS"] = {code: "GS", name: "South Georgia and the South Sandwich Islands"};
Countries["ES"] = {code: "ES", name: "Spain"};
Countries["LK"] = {code: "LK", name: "Sri Lanka"};
Countries["SD"] = {code: "SD", name: "Sudan"};
Countries["SR"] = {code: "SR", name: "Suriname"};
Countries["SJ"] = {code: "SJ", name: "Svalbard and Jan Mayen"};
Countries["SZ"] = {code: "SZ", name: "Swaziland"};
Countries["SE"] = {code: "SE", name: "Sweden"};
Countries["CH"] = {code: "CH", name: "Switzerland"};
Countries["SY"] = {code: "SY", name: "Syrian Arab Republic"};
Countries["TW"] = {code: "TW", name: "Taiwan"};
Countries["TJ"] = {code: "TJ", name: "Tajikistan"};
Countries["TZ"] = {code: "TZ", name: "Tanzania, United Republic of"};
Countries["TH"] = {code: "TH", name: "Thailand"};
Countries["TL"] = {code: "TL", name: "Timor-Leste"};
Countries["TG"] = {code: "TG", name: "Togo"};
Countries["TK"] = {code: "TK", name: "Tokelau"};
Countries["TO"] = {code: "TO", name: "Tonga"};
Countries["TT"] = {code: "TT", name: "Trinidad and Tobago"};
Countries["TN"] = {code: "TN", name: "Tunisia"};
Countries["TR"] = {code: "TR", name: "Turkey"};
Countries["TM"] = {code: "TM", name: "Turkmenistan"};
Countries["TC"] = {code: "TC", name: "Turks and Caicos Islands"};
Countries["TV"] = {code: "TV", name: "Tuvalu"};
Countries["UG"] = {code: "UG", name: "Uganda"};
Countries["UA"] = {code: "UA", name: "Ukraine"};
Countries["AE"] = {code: "AE", name: "United Arab Emirates"};
Countries["UK"] = {code: "UK", name: "United Kingdom"};
Countries["US"] = {code: "US", name: "United States"};
Countries["UM"] = {code: "UM", name: "United States Minor Outlying Islands"};
Countries["UY"] = {code: "UY", name: "Uruguay"};
Countries["UZ"] = {code: "UZ", name: "Uzbekistan"};
Countries["VU"] = {code: "VU", name: "Vanuatu"};
Countries["VE"] = {code: "VE", name: "Venezuela"};
Countries["VN"] = {code: "VN", name: "Viet Nam"};
Countries["VG"] = {code: "VG", name: "Virgin Islands, British"};
Countries["VI"] = {code: "VI", name: "Virgin Islands, U.S."};
Countries["WF"] = {code: "WF", name: "Wallis and Futuna"};
Countries["EH"] = {code: "EH", name: "Western Sahara"};
Countries["YE"] = {code: "YE", name: "Yemen"};
Countries["ZM"] = {code: "ZM", name: "Zambia"};
Countries["ZW"] = {code: "ZW", name: "Zimbabwe"};