import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon, MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {XMarkIcon} from "@heroicons/react/24/outline";
import React, {Fragment, useState} from "react";
import {Topic, Topics} from "../../enums/topics";

export default function TopicsSelector(props:{topicCodes:string[], updated:(topicCodes:string[]) => void}) {
    let selectedTopics = props.topicCodes.map(t => Topics[t]).filter(t => t !== undefined);
    const [topics, setTopics] = useState<Topic[]>(selectedTopics);
    const [topicsListFilter, setTopicsListFilter] = useState("");

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    const topicsListFiltered =
        topicsListFilter === ''
            ? Object.entries(Topics)
            : Object.entries(Topics).filter(([key, topic]) => {
                return topic.name.toLowerCase().includes(topicsListFilter.toLowerCase());
            });

    function updateTopics(topics: Topic[]) {
        setTopics(topics);
        let codes = topics.map((t) => t.code);
        props.updated(codes);
    }

    function resetTopics() {
        setTopics([]);
        props.updated([]);
    }

    return (
        <Listbox value={topics} onChange={updateTopics} multiple>
            {({ open }) => (
                <>
                    <div className="relative">
                        <div className="flex">
                            <div className="grow">
                                <Listbox.Button
                                    className="relative w-full cursor-default rounded-l-md bg-white py-[4px] pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-2 focus:ring-green-200 sm:text-sm sm:leading-6">
                                    <span className="block overflow-hidden h-6">
                                        {topics.length > 0 && (
                                            <>{topics.map((topic) => (
                                                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                                    <img src={`/img/topics/${topic.image}.png`}
                                                         alt={topic.name}
                                                         className="h-4 inline"
                                                    />
                                                    {topic.name}
                                                </span>
                                            ))}</>)
                                        }
                                        {topics.length === 0 && (<span className="text-gray-400">Topics...</span>)}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </Listbox.Button>
                            </div>
                            <div className="shrink">
                                <button
                                    type="button"
                                    className="inline-flex gap-x-1.5 rounded-r-md bg-gray-300 -ml-px p-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300"
                                    onClick={resetTopics}
                                >
                                    <XMarkIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                                </button>
                            </div>
                        </div>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options>
                                <div>
                                    <div
                                        className="absolute z-20 flex mt-1 w-full rounded-t-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        <input type="text"
                                               placeholder="Search..."
                                               className="w-full h-7 p-0.5 ml-8 text-xs rounded-md text-gray-500 placeholder:text-gray-400"
                                               onKeyDown={(e) => {e.stopPropagation();}}
                                               onChange={(e) => {setTopicsListFilter(e.target.value);}}
                                               value={topicsListFilter}
                                        />
                                        <MagnifyingGlassIcon className="absolute top-0 w-4 my-1.5 mx-2 text-gray-300"/>
                                        <button
                                            className="absolute top-0 right-0"
                                            onClick={()=>{setTopicsListFilter("")}}
                                        >
                                            <XMarkIcon className="h-5 w-5 m-1 text-gray-300" aria-hidden="true" />
                                        </button>

                                    </div>
                                    <div className="absolute z-20 mt-8 max-h-60 w-full overflow-auto rounded-b-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {topicsListFiltered.map(([key, topic]) => (
                                            <Listbox.Option
                                                key={key}
                                                className={({ active }) =>
                                                    classNames(
                                                        active ? 'bg-green-100' : '',
                                                        'relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900'
                                                    )
                                                }
                                                value={topic}
                                            >
                                                {({ selected, active }) => (
                                                    <>
                                                        <img src={`/img/topics/${topic.image}.png`}
                                                             alt={topic.name}
                                                             className="h-4 inline mr-1 align-middle"
                                                        />
                                                        <span
                                                            className={classNames(selected ? 'font-semibold' : 'font-normal', ' align-middle')}>
                                                                        {topic.name}
                                                                    </span>

                                                        {selected ? (
                                                            <span
                                                                className="absolute inset-y-0 left-2 flex items-center pr-4 text-green-600"
                                                            >
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                        {topicsListFiltered.length === 0 &&
                                            <div className="px-8 py-4 text-gray-500 text-xs">Nothing found...</div>}
                                    </div>
                                </div>
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
}