import {Link, useLocation} from "react-router-dom";
import {JSXElementConstructor, ReactElement} from "react";

interface MenuLinkProps {
    children: string | ReactElement<any, string | JSXElementConstructor<any>>;
    to: string;
}
export default function MenuLink(props:MenuLinkProps){

    const location = useLocation();
    const active = location.pathname == props.to;
    const classNames = getClasses(active);

    return (
        <Link to={props.to} className={classNames}>
            {props.children}
        </Link>);
}

function getClasses(active:boolean){
    if (active)
        return "inline-flex items-center border-b-2 border-green-500 px-1 pt-1 text-sm font-medium text-gray-900";
    return "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700";
}