import {BarsArrowDownIcon, BarsArrowUpIcon} from "@heroicons/react/20/solid";
import React from "react";

export function SortByColumnButton(props: { columnName: string, sortColumn: string, sortAsc: boolean, onClick: () => void }) {
    return <>
        {props.sortColumn != props.columnName &&
            <BarsArrowDownIcon className="w-4 h-4 text-gray-400 ml-2 inline cursor-pointer hover:text-gray-600"
                               onClick={props.onClick}></BarsArrowDownIcon>
        }
        {props.sortColumn == props.columnName && props.sortAsc &&
            <BarsArrowDownIcon className="w-4 h-4 text-green-400 ml-2 inline cursor-pointer hover:text-green-600"
                               onClick={props.onClick}></BarsArrowDownIcon>
        }
        {props.sortColumn == props.columnName && !props.sortAsc &&
            <BarsArrowUpIcon className="w-4 h-4 text-green-400 ml-2 inline cursor-pointer hover:text-green-600"
                             onClick={props.onClick}></BarsArrowUpIcon>
        }
    </>;
}