import React from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/20/solid";
import {Pagination} from "@makotot/ghostui";

export function TablePagination(props: { pageNumber: number, totalRows: number, rowsPerPage: number, onGoToPage: (page: number) => void }) {

    let totalPages = Math.ceil(props.totalRows / props.rowsPerPage);
    let firstRowIndex = (props.pageNumber - 1) * props.rowsPerPage + 1;
    if (firstRowIndex < 0) firstRowIndex = 0;
    if (firstRowIndex > props.totalRows) firstRowIndex = props.totalRows;
    let lastRowIndex = props.pageNumber * props.rowsPerPage;
    if (lastRowIndex < 0) lastRowIndex = 0;
    if (lastRowIndex > props.totalRows) lastRowIndex = props.totalRows;

    function goToPagePrevious() {
        if (props.pageNumber > 1) {
            goToPage(props.pageNumber - 1)
        }
        return false;
    }

    function goToPageNext() {
        if (props.pageNumber < totalPages) {
            goToPage(props.pageNumber + 1)
        }
        return false;
    }

    function goToPage(page:number) {
        props.onGoToPage(page);
        return false;
    }

    return (
        <Pagination
            currentPage={props.pageNumber}
            totalPage={totalPages}
            middlePagesSiblingCount={2}
            edgePageCount={3}
        >

            {(paginationProps) => (
                <>
                {props.totalRows > 0 &&
                    <div>
                        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3">
                            <div className="flex flex-1 justify-between sm:hidden">
                                <span
                                    className="relative cursor-pointer inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    onClick={goToPagePrevious}
                                >
                                    Previous
                                </span>
                                <span
                                    className="relative cursor-pointer ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                                    onClick={goToPageNext}
                                >
                                    Next
                                </span>
                            </div>
                            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                                <div>
                                    <p className="text-sm text-gray-700">
                                        Showing <span className="font-medium">{firstRowIndex}</span> to <span className="font-medium">{lastRowIndex}</span> of{' '}
                                        <span className="font-medium">{props.totalRows}</span> results
                                    </p>
                                </div>
                                {totalPages > 1 &&
                                    <div>
                                        <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                                            <span className="relative cursor-pointer inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                               onClick={goToPagePrevious}>
                                                <span className="sr-only">Previous</span>
                                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                            {paginationProps.getPreviousPages().map((page) => {
                                                return (
                                                    <span
                                                       className="relative cursor-pointer hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
                                                       onClick={() => {return goToPage(page);}}>
                                                        {page}
                                                    </span>
                                                );
                                            })}
                                            {paginationProps.isPreviousTruncable() &&
                                                <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
                                            }
                                            <div>
                                                {paginationProps.getMiddlePages().map((page) => {
                                                    return (
                                                        <span className={
                                                            paginationProps.currentPage === page ?
                                                            "relative cursor-pointer hidden items-center px-4 py-2 text-sm font-bold text-gray-900 ring-1 ring-inset ring-green-300 bg-green-100 hover:bg-green-200 focus:z-20 focus:outline-offset-0 md:inline-flex" :
                                                            "relative cursor-pointer hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
                                                            }
                                                           onClick={() => {return goToPage(page);}}>
                                                            {page}
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                            {paginationProps.isNextTruncable() &&
                                                <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
                                            }
                                            {paginationProps.getNextPages().map((page) => {
                                                return (
                                                    <span className="relative cursor-pointer hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
                                                       onClick={() => {return goToPage(page);}}>
                                                        {page}
                                                    </span>
                                                );
                                            })}
                                            <span className="relative cursor-pointer inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                                onClick={goToPageNext}
                                            >
                                                <span className="sr-only">Next</span>
                                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        </nav>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                </>
            )}
        </Pagination>
    );
}