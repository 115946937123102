import {API_URL} from "../config";
import UpdateUserProfileRequest from "../classes/UpdateUserProfileRequest";

export function updateUserProfile(token:string, request:UpdateUserProfileRequest) {
    return fetch(`${API_URL}/userprofile`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
    }).then(response => {
        return response.json();
    });
}