import {API_URL} from "../config";

export function getFavoritesWebsites(token:string, pageNumber:number) {
    let queryString = new URLSearchParams({pageNumber: `${pageNumber}`}).toString();
    return fetch(`${API_URL}/favorites?${queryString}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        }
    }).then(response => {
        return response.json();
    });
}

export function deleteFavoriteWebsite(token:string, websiteId:number) {
    let queryString = new URLSearchParams({websiteId: `${websiteId}`}).toString();
    return fetch(`${API_URL}/favorites?${queryString}`, {
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        }
    }).then(response => {
        return response.json();
    });
}