import React, {useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {AUTH0_AUDIENCE} from "../../config";
import Website from "../../classes/Website";
import {getWebsites} from "../../services/websites";
import WebsitesFilter from "../../classes/WebsitesFilter";
import {SortByColumnButton} from "../../components/Table/SortByColumnButton";
import {Countries} from "../../enums/countries";
import {Topics} from "../../enums/topics";
import {
    ArrowTopRightOnSquareIcon,
    CheckBadgeIcon
} from "@heroicons/react/20/solid";
import {SelectButton} from "./SelectButton";
import SuccessModal from "../../components/SuccessModal";
import {Spinner} from "../../components/Spinner";
import {FaceFrownIcon} from "@heroicons/react/24/outline";
import {TablePagination} from "../../components/Table/TablePagination";
import UserPermissions from "../../classes/UserPermissions";
import {EditButton} from "./EditButton";
import {DeleteButton} from "./DeleteButton";
import EditWebsiteModal from "./EditWebsiteModal";
import CountriesSelector from "./CountriesSelector";
import TopicsSelector from "./TopicsSelector";
import {AddButton} from "./AddButton";
import UploadWebsitesModal from "./UploadWebsitesModal";
import NumberFromToSelector from "./NumberFromToSelector";
import StringSearchSelector from "./StringSearchSelector";
import YesNoSelector from "./YesNoSelector";

export default function Marketplace(props:{userPermissions: UserPermissions|undefined;}) {
    const {getAccessTokenSilently} = useAuth0();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [websitesFilter, setWebsitesFilter] = useState<WebsitesFilter>({
        name: "",
        priceFrom: undefined,
        priceTo: undefined,
        organicFrom: undefined,
        organicTo: undefined,
        drFrom: undefined,
        drTo: undefined,
        geo: [],
        topic: [],
        acceptGambling: undefined,
        sortColumn: "name",
        sortAsc: true
    });

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [websites, setWebsites] = useState<Website[]>([]);
    const [isSuccessModalDisplayed, setIsSuccessModalDisplayed] = useState<boolean>(false);
    const displaySelectionButtons = props.userPermissions !== undefined && !props.userPermissions.websitesModify;
    const displayModificationButtons = props.userPermissions !== undefined && props.userPermissions.websitesModify;
    const [isWebsiteEditDisplayed, setIsWebsiteEditDisplayed] = useState<boolean>(false);
    const [websiteToEdit, setWebsiteToEdit] = useState<Website|undefined>(undefined);
    const [isWebsitesUploadDisplayed, setIsWebsitesUploadDisplayed] = useState<boolean>(false);

    let fetchData = (page:number) => {
        if (!isFetching) {
            setIsFetching(true);
            setWebsites([]);
            getAccessTokenSilently({
                authorizationParams: {
                    audience: AUTH0_AUDIENCE
                }
            }).then((token) => {
                getWebsites(token, page, websitesFilter).then((data) => {
                    setPageNumber(page);
                    setTotalRows(data.totalRows);
                    setWebsites(data.websites);
                    setIsFetching(false);
                });
            });
        }
    };

    useEffect(() => {
        filterData();
    }, []);

    function displaySuccessForm(website: Website) {
        setIsSuccessModalDisplayed(true);
    }

    function filterData() {
        setWebsitesFilter(websitesFilter);
        setPageNumber(1);
        setWebsites([]);
        fetchData(1);
    }

    function updateSort(name: string) {
        if (websitesFilter.sortColumn === name)
            websitesFilter.sortAsc = !websitesFilter.sortAsc;
        else {
            websitesFilter.sortColumn = name;
            websitesFilter.sortAsc = true;
        }
        filterData();

        return undefined;
    }
    function updateNameFilter(value: string) {
        websitesFilter.name = value;
    }

    function updateCountriesFilter(countryCodes: string[]) {
        websitesFilter.geo = countryCodes;
        filterData();
    }

    function updateTopicsFilter(topicCodes: string[]) {
        websitesFilter.topic = topicCodes;
        filterData();
    }

    function updatePriceFilter(from: number|undefined, to: number|undefined) {
        websitesFilter.priceFrom = from;
        websitesFilter.priceTo = to;
    }

    function updateOrganicFilter(from: number|undefined, to: number|undefined) {
        websitesFilter.organicFrom = from;
        websitesFilter.organicTo = to;
    }

    function updateDRFilter(from: number|undefined, to: number|undefined) {
        websitesFilter.drFrom = from;
        websitesFilter.drTo = to;
    }

    function updateAcceptGamblingFilter(acceptGambling:boolean|undefined) {
        websitesFilter.acceptGambling = acceptGambling;
        filterData();
    }

    function websiteModified(isNew: boolean, website: Website) {
        if (isNew) {
            let websitesNew = [website, ...websites];
            setWebsites(websitesNew);
        }
        else {
            let websitesNew = [...websites];
            let index = websitesNew.map(function(w) { return w.id; }).indexOf(website.id);
            if (index !== -1) {
                websitesNew[index] = website;
                setWebsites(websitesNew);
            }
        }
        setIsWebsiteEditDisplayed(false);
    }

    function websiteDeleted(website: Website) {
        let websitesNew = websites.filter(w => w.id !== website.id);
        setWebsites(websitesNew);
    }

    function websitesUploaded() {
    }

    return (
        <>
            {isSuccessModalDisplayed &&
                <SuccessModal onClose={() => {setIsSuccessModalDisplayed(false)}}
                              header="Request sent!"
                              text="One of our account managers will contact you ASAP regarding this website."
                              buttonText="Back to select more websites"
                />
            }

            {isWebsiteEditDisplayed &&
                <EditWebsiteModal onClose={()=>{setIsWebsiteEditDisplayed(false)}}
                                  onWebsiteModified={websiteModified}
                                  website={websiteToEdit}
                                  open={isWebsiteEditDisplayed}/>
            }

            {isWebsitesUploadDisplayed &&
                <UploadWebsitesModal onClose={()=>{setIsWebsitesUploadDisplayed(false)}}
                                  onWebsitesUploaded={websitesUploaded}
                                  open={isWebsitesUploadDisplayed}/>
            }

            <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 mt-10">
                <div>
                    <div className="block text-xs font-medium leading-6 text-gray-600">GEO</div>
                    <CountriesSelector countryCodes={[]} updated={updateCountriesFilter}/>
                </div>
                <div>
                    <label htmlFor="priceFilterFrom" className="block text-xs font-medium leading-6 text-gray-600">
                        Price range
                    </label>
                    <NumberFromToSelector
                        name="priceFilter"
                        from={websitesFilter.priceFrom}
                        to={websitesFilter.priceTo}
                        updated={updatePriceFilter}
                        search={filterData}
                    />
                </div>
                <div>
                    <label htmlFor="organicFilterFrom" className="block text-xs font-medium leading-6 text-gray-600">
                        Organic range
                    </label>
                    <NumberFromToSelector
                        name="organicFilter"
                        from={websitesFilter.organicFrom}
                        to={websitesFilter.organicTo}
                        updated={updateOrganicFilter}
                        search={filterData}
                    />
                </div>
                <div>
                    <label htmlFor="drFilterFrom" className="block text-xs font-medium leading-6 text-gray-600">
                        DR range
                    </label>
                    <NumberFromToSelector
                        name="drFilter"
                        from={websitesFilter.drFrom}
                        to={websitesFilter.drTo}
                        updated={updateDRFilter}
                        search={filterData}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-4 mt-4">
                <div>

                    <div className="block text-xs font-medium leading-6 text-gray-600">Accept Gambling</div>
                    <YesNoSelector value={websitesFilter.acceptGambling} updated={updateAcceptGamblingFilter}/>
                </div>
                <div>
                    <div className="block text-xs font-medium leading-6 text-gray-600">Topics</div>
                    <TopicsSelector topicCodes={[]} updated={updateTopicsFilter}/>
                </div>
                <div className="lg:col-span-2">
                    <label htmlFor="nameFilter" className="block text-xs font-medium leading-6 text-gray-600">
                        Website
                    </label>
                    <StringSearchSelector
                        name="nameFilter"
                        value={websitesFilter.name}
                        updated={updateNameFilter}
                        search={filterData}
                    />
                </div>
            </div>

            <div>
                <div className="-mx-4 mt-8 sm:-mx-0">
                    <table className="min-w-full">
                        <thead className="sticky top-0 bg-white z-10 shadow-gray-300"
                            style={{boxShadow: "0px 0.5px 0px 0px rgb(209, 213, 219), 0px -0.5px 0px 0px rgb(209, 213, 219)"}}>
                            <tr>
                                <th scope="col"
                                    className="sm:py-3.5 py-6 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                    Website
                                    <SortByColumnButton columnName="name"
                                                        sortColumn={websitesFilter.sortColumn}
                                                        sortAsc={websitesFilter.sortAsc}
                                                        onClick={() => {
                                                            updateSort("name");
                                                        }}/>
                                </th>
                                <th scope="col"
                                    className="hidden w-72 min-w-full px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell">
                                    GEO
                                </th>
                                <th scope="col"
                                    className="hidden w-72 min-w-full px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:table-cell">
                                    Topic
                                </th>
                                <th scope="col"
                                    className="hidden w-28 min-w-full px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:table-cell">
                                    Organic
                                    <SortByColumnButton columnName="organic"
                                                        sortColumn={websitesFilter.sortColumn}
                                                        sortAsc={websitesFilter.sortAsc}
                                                        onClick={() => {
                                                            updateSort("organic");
                                                        }}/>
                                </th>
                                <th scope="col"
                                    className="hidden w-20 min-w-full px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:table-cell">
                                    DR
                                    <SortByColumnButton columnName="dr"
                                                        sortColumn={websitesFilter.sortColumn}
                                                        sortAsc={websitesFilter.sortAsc}
                                                        onClick={() => {
                                                            updateSort("dr");
                                                        }}/>
                                </th>
                                <th scope="col"
                                    className="hidden w-20 px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:table-cell">
                                    Accept gambling
                                </th>
                                <th scope="col"
                                    className="hidden w-20 min-w-full px-3 py-3.5 text-center text-sm font-semibold text-gray-900 sm:table-cell">
                                    Price
                                    <SortByColumnButton columnName="price"
                                                        sortColumn={websitesFilter.sortColumn}
                                                        sortAsc={websitesFilter.sortAsc}
                                                        onClick={() => {
                                                            updateSort("price");
                                                        }}/>
                                </th>
                                <th scope="col" className="w-28 text-right min-w-full py-3.5 pl-3 pr-4">
                                    {displayModificationButtons &&
                                        <>
                                            <AddButton onWebsiteEdit={(website) => {
                                                setIsWebsiteEditDisplayed(true);
                                                setWebsiteToEdit(website);
                                            }} />
                                            <button
                                                type="button"
                                                className="text-center rounded-md bg-green-600 p-1 text-xs font-semibold text-white shadow-sm hover:bg-green-500 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-green-400"
                                                onClick={() => {
                                                    setIsWebsitesUploadDisplayed(true);
                                                }}
                                            >
                                                Upload
                                            </button>
                                        </>
                                    }
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                        {websites.map((website) => (
                            <tr key={website.id}>
                                <td className="w-full whitespace-nowrap max-w-0 py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                    {website.name}
                                    <a href={`http://${website.name}`} target="_blank">
                                        <ArrowTopRightOnSquareIcon
                                            className="w-4 h-4 ml-2 mb-0.5 inline text-gray-400 hover:text-gray-300"/>
                                    </a>

                                    <div className="lg:hidden">
                                        <div className="mt-1">
                                            {website.geo.map((geo) => (
                                                <span className="inline-flex text-left gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                                    <img src={`/img/country/${Countries[geo]?.code || geo}.svg`}
                                                         alt={Countries[geo]?.name}
                                                         className="h-3 inline mt-0.5"
                                                    />
                                                    {Countries[geo]?.code}
                                                </span>
                                            ))}
                                        </div>
                                        <div className="mt-2 sm:hidden">
                                            {website.topic && website.topic.map((topic) => (
                                                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                                    {Topics[topic] !== null &&
                                                        <img src={`/img/topics/${Topics[topic]?.image}.png`}
                                                             alt={Topics[topic]?.image}
                                                             className="h-4 inline"
                                                        />
                                                    }
                                                    {Topics[topic] !== null ? Topics[topic]?.name : topic}
                                                </span>
                                            ))}
                                        </div>
                                    </div>

                                </td>
                                <td className="hidden px-3 py-1 text-sm text-center text-gray-500 lg:table-cell">{website.geo.map((geo) => (
                                    <span
                                        className="inline-flex text-left gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                        <img src={`/img/country/${Countries[geo]?.code || geo}.svg`}
                                             alt={Countries[geo]?.name}
                                             className="h-3 inline mt-0.5"
                                        />
                                        {Countries[geo]?.code}
                                    </span>
                                ))}</td>
                                <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">{website.topic && website.topic.map((topic) => (
                                    <span
                                        className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                                        {Topics[topic] !== null &&
                                            <img src={`/img/topics/${Topics[topic]?.image}.png`}
                                                 alt={Topics[topic]?.image}
                                                 className="h-4 inline"
                                            />
                                        }
                                        {Topics[topic] !== null ? Topics[topic]?.name : topic}
                                    </span>
                                ))}</td>
                                <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">{website.organic}</td>
                                <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">{website.dr}</td>
                                <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">
                                    {website.acceptGambling &&
                                        <CheckBadgeIcon className="w-4 h-4 inline text-green-400"/>
                                    }
                                </td>
                                <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">
                                    €<span className="text-xl">{website.price}</span>
                                </td>
                                <td className="align-top py-1 pl-3 pr-4 text-right text-sm font-medium">
                                    {displaySelectionButtons &&
                                        <>
                                            <SelectButton onWebsiteAdded={() => {
                                                displaySuccessForm(website);
                                            }} website={website}/>
                                            <div className="w-28 text-center mt-3 truncate text-gray-500 sm:hidden">
                                                €<span className="text-xl">{website.price}</span>
                                            </div>
                                        </>
                                    }
                                    {displayModificationButtons &&
                                        <>
                                            <EditButton onWebsiteEdit={() => {
                                                setIsWebsiteEditDisplayed(true);
                                                setWebsiteToEdit(website);
                                            }} website={website}/>
                                            <DeleteButton onWebsiteDeleted={websiteDeleted} website={website}/>
                                        </>}

                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    {isFetching &&
                        <div role="status" className="py-20 text-center">
                            <Spinner className="inline w-10 h-10 mr-2 text-gray-200 animate-spin fill-green-600"/>
                        </div>
                    }

                    {websites.length === 0 && !isFetching &&
                        <div className="text-center my-32">
                            <FaceFrownIcon className="mx-auto h-12 w-12 text-gray-300"/>
                            <h3 className="mt-2 text-sm font-semibold text-gray-900">Nothing found</h3>
                            <p className="mt-1 text-sm text-gray-500">Contact us if you have special requirement.</p>
                        </div>
                    }
                    <TablePagination pageNumber={pageNumber} totalRows={totalRows} rowsPerPage={25} onGoToPage={(page) => fetchData(page)}/>
                </div>
            </div>
        </>
    );
}
