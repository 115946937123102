import Website from "../../classes/Website";
import React, {useState} from "react";

export function EditButton(props: { onWebsiteEdit: (website: Website) => void, website: Website }) {
    function buttonClick() {
        props.onWebsiteEdit(props.website);
    }

    return <button
        type="button"
        className="text-center rounded-md bg-green-600 p-1 mr-1.5 text-xs font-semibold text-white shadow-sm hover:bg-green-500 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-green-400"
        onClick={buttonClick}
    >
        Edit
    </button>;
}