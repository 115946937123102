import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";
import {XMarkIcon} from "@heroicons/react/24/outline";
import React, {Fragment, useEffect, useState} from "react";
import {YesNoOptions} from "../../enums/YesNoOptions";

export default function YesNoSelector(
    props:{
        value:boolean|undefined,
        updated:(value:boolean|undefined) => void
    }
) {
    const initValue = props.value === undefined ? "All" : props.value ? "Yes" : "No";
    const [value, setValue] = useState<string>(initValue);
    const [text, setText] = useState<string>("");

    function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
    }

    useEffect(() => {
        setText(YesNoOptions[value].name);
    }, [value]);

    function resetValue() {
        setValue("All");
        props.updated(YesNoOptions["All"].value);
    }

    function valueUpdated(val:string) {
        setValue(val);
        props.updated(YesNoOptions[val].value);
    }

    return (
        <Listbox value={value} onChange={valueUpdated} >
            {({ open }) => (
                <>
                    <div className="relative">
                        <div className="flex">
                            <div className="grow">
                                <Listbox.Button
                                    className="relative w-full cursor-default rounded-l-md bg-white py-1 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:outline-none focus:ring-2 focus:ring-green-200 sm:text-sm sm:leading-6">
                                    <span className="block">
                                        {text}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </Listbox.Button>
                            </div>
                            <div className="shrink">
                                <button
                                    type="button"
                                    className="inline-flex gap-x-1.5 rounded-r-md bg-gray-300 -ml-px p-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-300"
                                    onClick={resetValue}
                                >
                                    <XMarkIcon className="-ml-0.5 h-4 w-4" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {Object.entries(YesNoOptions).map(([key, option]) => (
                                    <Listbox.Option
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'bg-green-100 text-gray-900' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-8 pr-4'
                                            )
                                        }
                                        key={key}
                                        value={option.code}>
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={classNames(selected ? 'font-semibold' : 'font-normal', ' align-middle')}>
                                                    {option.name}
                                                </span>

                                                {selected ? (
                                                    <span className="absolute text-green-600 inset-y-0 left-2 flex items-center pr-4">
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
}