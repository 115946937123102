import {API_URL} from "../config";

export function getUserPermissions(token:string) {
   return fetch(`${API_URL}/permissions`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json"
        }
    }).then(response => {
        return response.json()
    });
}