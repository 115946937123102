import React, { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Website from "../../classes/Website";
import {SubmitHandler, useForm} from "react-hook-form";
import {Spinner} from "../../components/Spinner";
import CountriesSelector from "./CountriesSelector";
import TopicsSelector from "./TopicsSelector";
import {AUTH0_AUDIENCE} from "../../config";
import {useAuth0} from "@auth0/auth0-react";
import {addWebsite, updateWebsite, uploadWebsites} from "../../services/websites";

interface IFormInput {
    files: FileList | undefined;
}

export default function UploadWebsitesModal(props: { onClose: () => void, onWebsitesUploaded: () => void, open:boolean }) {
    const {user, getAccessTokenSilently} = useAuth0();
    const [uploadResult, setUploadResult] = useState<any>(undefined);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const { register, getValues, setValue, handleSubmit } = useForm<IFormInput>({
        values: {
            files: undefined
        }
    });
    function closeModal() {
        props.onClose();
    }

    const onSubmit: SubmitHandler<IFormInput> = (inputData) =>  {
        setIsProcessing(true);
        setUploadResult(undefined);
        getAccessTokenSilently({
            authorizationParams: {
                audience: AUTH0_AUDIENCE
            }
        }).then((token) => {
            if (inputData.files === undefined || inputData.files?.length < 0)
                return;

            uploadWebsites(token, inputData.files[0]).then((result) => {
                setUploadResult(result);
                setIsProcessing(false);
                props.onWebsitesUploaded();
            });
        });
    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                                        <div className="px-4 sm:px-6">
                                            <div className="flex items-start justify-between">
                                                <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                                                    Upload Websites
                                                </Dialog.Title>
                                                <div className="ml-3 flex h-7 items-center">
                                                    <button
                                                        type="button"
                                                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                                        onClick={closeModal}
                                                        disabled={isProcessing}
                                                    >
                                                        <span className="absolute -inset-2.5" />
                                                        <span className="sr-only">Close panel</span>
                                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                            <form onSubmit={handleSubmit(onSubmit)} className="mx-auto mt-16 max-w-xl sm:mt-20">

                                                <label className="block mb-2 text-sm font-medium text-gray-900" htmlFor="file_input">Upload file</label>
                                                <input className="block w-full border-0 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 file:bg-green-600 file:text-white file:border-0 file:p-2 file:m-0 file:mr-2 rounded-md cursor-pointer focus:ring-2 focus:ring-inset focus:ring-green-600"
                                                       id="file"
                                                       {...register("files", { required: true })}
                                                       type="file"
                                                       accept=".xlsx"
                                                />
                                                <p className="mt-1 text-sm text-gray-500" id="file_input_help">XLSX file</p>
                                                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                                    <button
                                                        type="submit"
                                                        className="w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 sm:col-start-2"
                                                        disabled={isProcessing}
                                                    >

                                                        {isProcessing && <>
                                                            <Spinner className="inline w-4 h-4 mb-1 mr-1 text-green-200 animate-spin fill-white"/>
                                                            <span className="align-baseline">Processing...</span>
                                                        </>}
                                                        {!isProcessing && <>Proceed</>}
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                                        onClick={closeModal}
                                                        disabled={isProcessing}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </form>

                                            {uploadResult !== undefined &&
                                                <div className="w-full mt-5 h-60 text-xs scroll-auto overflow-scroll">
                                                    {uploadResult.success && <div className="text-green-600 mb-5 font-bold">Successfully completed</div>}
                                                    {!uploadResult.success && <div className="text-red-600 mb-5 font-bold">Something went worng</div>}
                                                    <div>Total rows found: {uploadResult.totalRowsRaw}</div>
                                                    <div>Total rows parsed: {uploadResult.totalRowsParsed}</div>
                                                    <div>Total rows unique: {uploadResult.totalRowsUnique}</div>
                                                    <div>Total rows insert: {uploadResult.totalRowsInserted}</div>
                                                    <div className="mt-5">
                                                        {uploadResult && uploadResult.messages !== undefined && uploadResult.messages.map((m:any) => {
                                                            return (
                                                                <div>{m}</div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
