import {API_URL} from "../config";
import WebsitesSearchesFilter from "../classes/WebsitesSearchesFilter";

export function getWebsitesSearches(token:string, pageNumber:number, filter:WebsitesSearchesFilter) {
    let queryString = new URLSearchParams({pageNumber: `${pageNumber}`}).toString();
    return fetch(`${API_URL}/websitessearches/search?${queryString}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify(filter)
    }).then(response => {
        return response.json();
    });
}