import React from 'react'
import './TopProgressBar.css';

function TopProgressBar() {
    return (
        <div className="w-full bg-green-500 h-1.5 dark:bg-green-200">
            <div className="bg-green-600 h-1.5 topProgressBarLineAnimation"></div>
        </div>
    );
}

export default TopProgressBar;
