import React, {useEffect, useState} from 'react';
import {AUTH0_AUDIENCE} from "../../config";
import {useAuth0} from "@auth0/auth0-react";
import UserRequest from "../../classes/UserRequest";
import {getUserRequests} from "../../services/userRequests";
import moment from "moment";
import {FaceFrownIcon} from "@heroicons/react/24/outline";
import {TablePagination} from "../../components/Table/TablePagination";
import {Spinner} from "../../components/Spinner";
import RequestsFilter from "../../classes/RequestsFilter";
import {SortByColumnButton} from "../../components/Table/SortByColumnButton";
import StringSearchSelector from "../Marketplace/StringSearchSelector";


export default function Requests() {
    const { getAccessTokenSilently } = useAuth0();
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [filter, setFilter] = useState<RequestsFilter>({
        email: "",
        website: "",
        sortColumn: "date",
        sortAsc: false
    });
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [userRequests, setUserRequests] = useState<UserRequest[]>([]);

    let fetchData = (page:number) => {
        if (!isFetching) {
            setIsFetching(true);
            setUserRequests([]);
            getAccessTokenSilently({
                authorizationParams: {
                    audience: AUTH0_AUDIENCE
                }
            }).then((token) => {
                getUserRequests(token, page, filter).then((data) => {
                    setPageNumber(page);
                    setTotalRows(data.totalRows);
                    setUserRequests(data.userRequests);
                    setIsFetching(false);
                });
            });
        }
    };
    useEffect(() => {
        filterData();
    }, []);

    function updateSort(name: string) {
        if (filter.sortColumn === name)
            filter.sortAsc = !filter.sortAsc;
        else {
            filter.sortColumn = name;
            filter.sortAsc = true;
        }
        filterData();

        return undefined;
    }

    function updateEmailFilter(value: string) {
        filter.email = value;
    }

    function updateWebsiteFilter(value: string) {
        filter.website = value;
    }

     function filterData() {
        setFilter(filter);
        setPageNumber(1);
        setUserRequests([]);
        fetchData(1);
    }

    return (
        <>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 mt-10">
                <div>
                    <label htmlFor="emailFilter" className="block text-xs font-medium leading-6 text-gray-600">
                        Email
                    </label>
                    <StringSearchSelector
                        name="emailFilter"
                        value={filter.email}
                        updated={updateEmailFilter}
                        search={filterData}
                    />
                </div>
                <div>
                    <label htmlFor="websiteFilter" className="block text-xs font-medium leading-6 text-gray-600">
                        Website
                    </label>
                    <StringSearchSelector
                        name="websiteFilter"
                        value={filter.website}
                        updated={updateWebsiteFilter}
                        search={filterData}
                    />
                </div>
            </div>
            <div className="pb-12">
                <div className="-mx-4 mt-8 sm:-mx-0">
                    <table className="min-w-full">
                        <thead className="sticky top-0 bg-white z-10 shadow-gray-300"
                            style={{boxShadow: "0px 0.5px 0px 0px rgb(209, 213, 219), 0px -0.5px 0px 0px rgb(209, 213, 219)"}}>
                            <tr>
                                <th scope="col" className="sm:w-60 sm:py-3.5 py-6 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
                                    Email
                                    <SortByColumnButton columnName="email"
                                                        sortColumn={filter.sortColumn}
                                                        sortAsc={filter.sortAsc}
                                                        onClick={() => {
                                                            updateSort("email");
                                                        }}/>
                                </th>
                                <th scope="col" className="w-28 hidden px-3 py-3.5 text-sm text-center font-semibold text-gray-900 lg:table-cell">
                                    Name
                                </th>
                                <th scope="col" className="w-28 hidden px-3 py-3.5 text-sm text-center font-semibold text-gray-900 sm:table-cell">
                                    Phone
                                </th>
                                <th scope="col" className="w-72 hidden px-3 py-3.5 text-sm text-center font-semibold text-gray-900 sm:table-cell">
                                    Comment
                                </th>
                                <th scope="col" className="w-36 hidden px-3 py-3.5 text-sm text-center font-semibold text-gray-900 sm:table-cell">
                                    Website
                                    <SortByColumnButton columnName="website"
                                                        sortColumn={filter.sortColumn}
                                                        sortAsc={filter.sortAsc}
                                                        onClick={() => {
                                                            updateSort("website");
                                                        }}/>
                                </th>
                                <th scope="col" className="w-28 hidden px-3 py-3.5 text-sm text-center font-semibold text-gray-900 sm:table-cell">
                                    Price
                                </th>
                                <th scope="col" className="w-28 px-3 py-3.5 text-sm text-center font-semibold text-gray-900 sm:table-cell">
                                    Date
                                    <SortByColumnButton columnName="date"
                                                        sortColumn={filter.sortColumn}
                                                        sortAsc={filter.sortAsc}
                                                        onClick={() => {
                                                            updateSort("date");
                                                        }}/>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {userRequests.map((userRequest) => (
                                <tr key={userRequest.id}>
                                    <td className="py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
                                        <div>{userRequest.email}</div>
                                        <div className="mt-1 text-gray-700 sm:hidden">{userRequest.firstName} {userRequest.lastName}</div>
                                        <div className="mt-1 text-gray-500 sm:hidden">{userRequest.phone}</div>
                                        <div className="mt-1 text-gray-500 sm:hidden">{userRequest.websiteName}</div>
                                        <div className="mt-1 text-gray-500 sm:hidden">
                                            {userRequest.websitePrice && <>€<span className="text-xl">{userRequest.websitePrice}</span></>}
                                        </div>
                                    </td>
                                    <td className="hidden px-3 py-1 text-sm text-center text-gray-500 lg:table-cell">{userRequest.firstName} {userRequest.lastName}</td>
                                    <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">{userRequest.phone}</td>
                                    <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">{userRequest.comment}</td>
                                    <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">{userRequest.websiteName}</td>
                                    <td className="hidden px-3 py-1 text-sm text-center text-gray-500 sm:table-cell">
                                        {userRequest.websitePrice && <>€<span className="text-xl">{userRequest.websitePrice}</span></>}
                                    </td>
                                    <td className="px-3 py-1 pr-4 text-sm text-center text-gray-500">{moment(userRequest.createdOn).format('YYYY-MM-DD HH:mm')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {isFetching &&
                        <div role="status" className="py-20 text-center">
                            <Spinner className="inline w-10 h-10 mr-2 text-gray-200 animate-spin fill-green-600"/>
                        </div>
                    }
                    {userRequests.length == 0 && !isFetching &&
                        <div className="text-center">
                            <FaceFrownIcon className="mx-auto my-5 h-12 w-12 text-gray-300"/>
                            <h3 className="mt-2 text-sm font-semibold text-gray-900">No user requests found</h3>
                        </div>
                    }
                    <TablePagination pageNumber={pageNumber} totalRows={totalRows} rowsPerPage={25} onGoToPage={(page) => fetchData(page)}/>
                </div>
            </div>
        </>
    );
}
