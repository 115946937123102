import Website from "../../classes/Website";
import {useAuth0} from "@auth0/auth0-react";
import React, {useState} from "react";
import {AUTH0_AUDIENCE} from "../../config";
import UserRequestCreate from "../../classes/UserRequestCreate";
import {addUserRequest} from "../../services/userRequests";
import {Spinner} from "../../components/Spinner";

export function SelectButton(props: { onWebsiteAdded: (website: Website) => void, website: Website }) {
    const {user, getAccessTokenSilently} = useAuth0();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [website, setWebsite] = useState<Website>(props.website);

    function buttonClick() {
        setIsLoading(true);
        getAccessTokenSilently({
            authorizationParams: {
                audience: AUTH0_AUDIENCE
            }
        }).then((token) => {
            let request: UserRequestCreate = {
                firstName: user?.given_name,
                lastName: user?.family_name,
                email: user?.email,
                phone: user?.phone_number,
                websiteId: website?.id,
                comment: undefined
            };
            addUserRequest(token, request).then((data) => {
                website.isInMyFavorites = true;
                setWebsite(website);
                setIsLoading(false);
                props.onWebsiteAdded(website);
            });
        });
    }
    if (website.isInMyFavorites) {
        return <button
            type="button"
            className="w-full text-center rounded-md bg-gray-400 p-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-300 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-gray-300"
            disabled={true}
        >
            Selected
        </button>;
    }

    return <button
        type="button"
        className="w-full text-center rounded-md bg-green-600 p-1 text-sm font-semibold text-white shadow-sm hover:bg-green-500 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-green-400"
        onClick={buttonClick}
        disabled={isLoading}
    >
        {isLoading && <>
            <Spinner className="inline w-4 h-4 mb-1 mr-1 text-green-200 animate-spin fill-white"/>
            <span className="align-baseline">Adding...</span>
        </>}
        {!isLoading && <>Select<span className="sr-only">, {website.name}</span></>}
    </button>;
}