export interface Topic {
    code: string;
    name: string;
    image: string;
}

export let Topics: { [code: string] : Topic; } = {};
Topics["Banner"] = {code: "Banner", name: "Banner", image: "Banner"};
Topics["Basketball"] = {code: "Basketball", name: "Basketball", image: "Basketball"};
Topics["Betting"] = {code: "Betting", name: "Betting", image: "Betting"};
Topics["Business"] = {code: "Business", name: "Business", image: "Business"};
Topics["Career"] = {code: "Career", name: "Career", image: "Career"};
Topics["Cars"] = {code: "Cars", name: "Cars", image: "Cars"};
Topics["Casino"] = {code: "Casino", name: "Casino", image: "Casino"};
Topics["Coupons"] = {code: "Coupons", name: "Coupons", image: "Coupons"};
Topics["Crypto"] = {code: "Crypto", name: "Crypto", image: "Crypto"};
Topics["Design"] = {code: "Design", name: "Design", image: "Design"};
Topics["Education"] = {code: "Education", name: "Education", image: "Education"};
Topics["Entertainment"] = {code: "Entertainment", name: "Entertainment", image: "Entertainment"};
Topics["Fashion"] = {code: "Fashion", name: "Fashion", image: "Fashion"};
Topics["Film/TV"] = {code: "Film/TV", name: "Film/TV", image: "Film_TV"};
Topics["Finance"] = {code: "Finance", name: "Finance", image: "Finance"};
Topics["Food"] = {code: "Food", name: "Food", image: "Food"};
Topics["Football"] = {code: "Football", name: "Football", image: "Football"};
Topics["Gambling"] = {code: "Gambling", name: "Gambling", image: "Gambling"};
Topics["Gaming"] = {code: "Gaming", name: "Gaming", image: "Gaming"};
Topics["Golf"] = {code: "Golf", name: "Golf", image: "Golf"};
Topics["Health"] = {code: "Health", name: "Health", image: "Health"};
Topics["Horse"] = {code: "Horse", name: "Horse", image: "Horse"};
Topics["Horse racing"] = {code: "Horse racing", name: "Horse racing", image: "HorseRacing"};
Topics["House"] = {code: "House", name: "House", image: "House"};
Topics["Lifestyle"] = {code: "Lifestyle", name: "Lifestyle", image: "Lifestyle"};
Topics["Local"] = {code: "Local", name: "Local", image: "Local"};
Topics["Music"] = {code: "Music", name: "Music", image: "Music"};
Topics["News"] = {code: "News", name: "News", image: "News"};
Topics["Pets"] = {code: "Pets", name: "Pets", image: "Pets"};
Topics["Poker"] = {code: "Poker", name: "Poker", image: "Poker"};
Topics["Racing"] = {code: "Racing", name: "Racing", image: "Racing"};
Topics["Shop"] = {code: "Shop", name: "Shop", image: "Shop"};
Topics["Sports"] = {code: "Sports", name: "Sports", image: "Sports"};
Topics["Tech"] = {code: "Tech", name: "Tech", image: "Tech"};
Topics["Tennis"] = {code: "Tennis", name: "Tennis", image: "Tennis"};
Topics["Text Link"] = {code: "Text Link", name: "Text Link", image: "TextLink"};
Topics["Tourism"] = {code: "Tourism", name: "Tourism", image: "Tourism"};
Topics["VPN"] = {code: "VPN", name: "VPN", image: "VPN"};
Topics["Wedding"] = {code: "Wedding", name: "Wedding", image: "Wedding"};