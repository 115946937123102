import React, {useEffect, useState} from 'react';
import './App.css';
import TopMenu from "./components/TopMenu/TopMenu";
import {useAuth0} from "@auth0/auth0-react";
import {Route, Routes} from "react-router-dom";
import Marketplace from "./pages/Marketplace/Marketplace";
import Favorites from "./pages/Favorites/Favorites";
import Profile from "./pages/Profile/Profile";
import TopProgressBar from "./components/TopProgressBar/TopProgressBar";
import {getUserPermissions} from "./services/userPermissions";
import {AUTH0_AUDIENCE} from "./config";
import UserPermissions from "./classes/UserPermissions";
import Requests from "./pages/Requests/Requests";
import WebsitesSearches from "./pages/WebsitesSearches/WebsitesSearches";

export default function App() {
    const [userPermissions, setUserPermissions] = useState<UserPermissions|undefined>(undefined);
    const {loginWithRedirect, isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();

    useEffect(()=> {
        if (!isLoading && isAuthenticated && userPermissions === undefined) {
            getAccessTokenSilently({
                authorizationParams: {
                    audience: AUTH0_AUDIENCE
                }
            }).then((token) => {
                getUserPermissions(token).then((data) => {
                    setUserPermissions(data);
                });
            });
        }
    }, [isAuthenticated]);


    if (!isLoading && !isAuthenticated) {
        loginWithRedirect().then(()=>{});
        return <></>;
    }

    return (
        <>
            {(isLoading || userPermissions === undefined)&& <TopProgressBar></TopProgressBar>}
            {userPermissions !== undefined &&
            <>
                <TopMenu userPermissions={userPermissions}></TopMenu>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <Routes>
                        <Route path='/' element={<Marketplace userPermissions={userPermissions}/>}></Route>
                        <Route path='/favorites' element={<Favorites/>}></Route>
                        <Route path='/profile' element={<Profile/>}></Route>
                        <Route path='/requests' element={<Requests/>}></Route>
                        <Route path='/websites-searches' element={<WebsitesSearches/>}></Route>
                    </Routes>
                </div>
            </>}
        </>
    );
}
