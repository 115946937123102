import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Auth0Provider} from "@auth0/auth0-react";
import {BrowserRouter} from "react-router-dom";
import {AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN} from "./config";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Auth0Provider domain={AUTH0_DOMAIN}
                     clientId={AUTH0_CLIENT_ID}
                     authorizationParams={{
                         redirect_uri: window.location.origin,
                         authorizationParams: {
                             audience: AUTH0_AUDIENCE
                         }
                     }}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
      </Auth0Provider>
  </React.StrictMode>
);
